<template>
  <div v-loading="isLoading" class="agent-reference-list">
    <div v-if="!isLoading" class="agent-reference-list__content">
      <div class="agent-reference-list__title">
        Рефералы агента
      </div>
      <div v-if="!referrals.length && !isLoading" class="agent-reference-list">Нет рефералов</div>
      <el-table
        v-else
        :data="referrals"
        style="width: 100%"
        class="agent-reference-list__table"
      >
        <el-table-column width="auto">
          <template slot="header">
            Email
          </template>
          <template slot-scope="scope">
            <div class="cell">
              {{ scope.row.email }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import axios from "axios";
import { mapState, mapMutations, mapActions } from 'vuex'
import fixDateFormat from '@/mixin/fixDate'

export default {
  name: 'AgentReferenceList',
  mixins: [fixDateFormat],
  data () {
    return {
      isLoading: true,
      referrals: []
    }
  },
  computed: {
    
  },
  watch: {
    async '$route.query.agent_id'(agent_id) {
      const clientToken = localStorage.getItem('token')
      const data = {
        token: clientToken,
        agent_id
      }

      try {
        const response = await this.$store.dispatch('Referrals/getUsersByAgentId', data)
        this.referrals = response.data.data
      } catch(e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    }
  },
  methods: {
    
  },
  beforeDestroy() {
    const { agent_id, ...rest } = this.$route.query 
    this.$router.push({ query: { ...rest }})
  },
}
</script>
<style lang="scss">
.agent-reference-list__table.el-table td:last-child {
  text-align: left;
}
</style>
<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.agent-reference-list {
  background: #fff;
  color: #000;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 5rem;
  }

  &__content {
    padding: 7rem 6rem;
  }
}
</style>
