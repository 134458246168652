<template>
  <div class="deposit">
    <div v-if="!isSuccess" class="deposit__content">
      <div class="deposit__title">
        {{$t('deposit.title')}}
      </div>
      <div v-if="accountsListWithCampaigns && accountsListWithCampaigns.length" class="deposit__steps">
        <div class="deposit__step">
          <div class="deposit__step-title">
            1. {{$t('deposit.source')}}
          </div>
          <div class="deposit__step-content">
            <div class="deposit__balances">
              <label
                  class="deposit__balances-item"
                  :class="{'deposit__balances-item--active' : selectedPayType === 'balance'}"
              >
                <input
                    type="radio"
                    v-model="selectedPayType"
                    value="balance"
                >
                <img :src="getPic(locale)" class="deposit__balances-icon" alt="main balance"/>
                <span class="deposit__balances-info">
                  <span class="deposit__balances-title">
                    {{$t('deposit.ballance')}} {{ $t('currency.currency_name') }}
                  </span>
                  <span class="deposit__balances-value">
                    {{ activeAccount.balance ? locale==='USA'? numberWithSpacesFix2(activeAccount.balance) : numberWithSpacesCeil(activeAccount.balance) : 0 }} {{$t('currency.currency_symbol')}}
                  </span>
                </span>
              </label>
              <label
                v-if="activeAccount.cashback_balance && activeAccount.cashback_balance > 0"
                class="deposit__balances-item"
                :class="{'deposit__balances-item--active' : selectedPayType === 'cashback'}"
              >
                <input
                  type="radio"
                  v-model="selectedPayType"
                  value="cashback"
                >
                <img :src="getPic(locale)" class="deposit__balances-icon" alt="main balance"/>
                <span class="deposit__balances-info">
                  <span class="deposit__balances-title">
                    {{$t('deposit.cashback')}} {{ $t('currency.currency_name') }}
                  </span>
                  <span class="deposit__balances-value">
                    {{ activeAccount.cashback_balance ? locale === 'USA'? numberWithSpacesFix2(activeAccount.cashback_balance) : numberWithSpacesCeil(activeAccount.cashback_balance): 0 }} {{$t('currency.currency_symbol')}}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="deposit__step">
          <div class="deposit__step-title">
            2. {{$t('deposit.list_of_ad_campaigns')}}
          </div>
          <!-- <div class="deposit__step-content" :class="{'add-x-scroll':campaignList && campaignList.length}"> -->
          <div class="deposit__step-content">
            <div class="deposit__step-title--small">
              <!-- {{ getCurrentPlatformName ==='google' ? `${$t('prepaid.min-payment')} $${minGooglePayment}` : `${$t('prepaid.min-payment')} $${minPayment}` }} -->
              <!-- {{ getCurrentPlatformName === 'google' ? `${$t('prepaid.min-payment')} $${minGooglePayment}` : (getCurrentPlatformName !== 'google' && locale === 'USA' ? `${$t('prepaid.min-payment')} $${minPayment}` : $t('deposit.top_up_ad_account_balance')) }} -->
              <!-- {{ getCurrentPlatformName === 'google' ? `${$t('prepaid.min-payment')} $${minGooglePayment}` : ((getCurrentPlatformName === 'yandex') ? `${$t('prepaid.min-payment')} $${minYandexPayment}` : `${$t('prepaid.min-payment')} $${minPayment}`) }} -->
              <!-- {{ `${$t('prepaid.min-payment')} ${minPaymentAmount} ${selectedAccount.service === 'telegram' ? serviceCurrencyIcon[selectedAccount.service_currency] : '$'}` }} -->
              {{ selectedAccount.min_deposit ? `${$t('prepaid.min-payment')}  ${selectedAccount.min_deposit} ${serviceCurrencyIcon[serviceCurrency]}` : null }}
            </div>
            <div class="deposit__step-row">
              <div class="deposit__step-col">
                <div v-if="accountsListWithCampaigns && accountsListWithCampaigns.length" class="deposit__step-account-selector">
                  <div v-if="selectedAccount" class="deposit__step-account-selector-value" @click="showCampaignsList">
                    <span class="deposit__step-account-selector-name">
                      <img
                        v-if="selectedAccount.service === 'mytarget'"
                        src="@/assets/icons/icon-mytarget-logo.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'vkontakte'"
                        src="@/assets/icons/icon-vkontacte.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'vkads'"
                        src="@/assets/icons/icon-vkreklama.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'facebook'"
                        src="@/assets/icons/icon-meta.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'yandex'"
                        src="@/assets/icons/yandex-direct-icon.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'google'"
                        src="@/assets/icons/icon-google.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'tiktok'"
                        src="@/assets/icons/icon-tiktok.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'telegram'"
                        src="@/assets/icons/icon-telegram.svg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'promopages'"
                        src="@/assets/icons/icon-promo-page.jpeg"
                        alt="platform-logo"
                      >
                      <img
                        v-if="selectedAccount.service === 'yandexbusiness'"
                        src="@/assets/icons/icon-yandex-business.png"
                        alt="platform-logo"
                      >
                       <!-- {{ selectedAccount.service }} -->
                    </span>
                    <span class="deposit__step-account-selector-info">
                      <span
                        v-if="selectedAccount.description"
                        class="deposit__step-account-selector-desc"
                        v-html="selectedAccount.description"
                      />
                      <span class="deposit__step-account-selector-title">
                         {{ selectedAccount ? selectedAccount.account_name : '' }}
                      </span>
                      <img
                        class="deposit__step-account-selector-arrow"
                        :class="{ active: showOptions }"
                        src="@/assets/icons/icon-arrow.svg"
                        alt="arrow"
                      >
                    </span>
                  </div>
                  <div v-show="showOptions" class="deposit__step-account-selector-options">
                    <div
                        class="form-input deposit__step-account-selector-search"
                        :class="
                      [
                        {'form-input--full' : search}
                      ]"
                    >
                      <input v-model="search" type="text" required>
                      <span class="form-input__label">
                        {{ $t('search_props.placeholder') }}
                      </span>
                    </div>
                    <div
                        v-for="item in filtredAccountsList"
                        :key="item.id"
                        class="deposit__step-account-selector-item"
                        @click="selecteAccount(item)"
                    >
                      <span class="deposit__step-account-selector-service">
                        <!-- {{ item.service }} -->
                        <img 
                        :src="getLogo(item.service)"
                        alt="add account logo">
                      </span>
                      <span class="deposit__step-account-selector-info">
                        <span
                          v-if="item.description"
                          class="deposit__step-account-selector-desc"
                          v-html="item.description"
                        />
                        <span class="deposit__step-account-selector-title">
                          {{ item.account_name }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="deposit__step-col">
                <DepositAmount
                  :account-name="selectedAccount ? selectedAccount.account_name : ''"
                  :currency = "selectedAccount ? selectedAccount.service_currency : ''"
                  default-value="0"
                  :isCurrencyChange = isCurrencyChange
                  :addAccount = "selectedAccount ? selectedAccount.service : ''"
                />
              </div>
            </div>
            <div v-if="campaignList && campaignList.length && locale !=='USA'" class="deposit__step-title--small">
              {{$t('deposit.top_up_ad_campaigns')}}
            </div>
            <DepositCampaign v-if="campaignList && campaignList.length  && locale!=='USA'" 
              :list="campaignList" 
              :currency = "selectedAccount ? selectedAccount.service_currency : ''"
              :addAccount = "selectedAccount ? selectedAccount.service : ''"
               />
              <!--unlock after-->
              <div v-else-if="locale !== 'USA'">
                {{$t('deposit.no_match')}}
              </div>
            <div v-if="error && error.length" class="deposit__step-account-selector-error">
              <span v-for="(err, key) in error" :key="key + 'err'">
                {{ err }} <br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="deposit__step-none">
        {{$t('deposit.no_ad_accounts_to')}}
      </div>
    </div>
    <div v-if="!campaignList.length" class="deposit_buffer"  :style="{'min-height': campaignsHeight}"></div>
    <div v-if="!isSuccess && role !=='partner'" class="deposit-promo">
      <div class="prepaid__flex-wrapper">
              <div
                class="form-input"
                :class="[{'form-input--full' : form.promo_code},{'form-input--error' : errors.promo_code}]"
              >
                <input
                  v-model="form.promo_code"
                  @blur="checkPromo"
                >
                <span class="form-input__label">{{$t('prepaid.promo_code')}}</span>
                <img v-if="promosSuccess" class="prepaid__success-icon" src="../../assets/icons/icon-check.svg" alt="Icon success"/>
                <div
                    v-if="errors.promo_code"
                    class="form-input__error"
                    v-text="errors.promo_code"
                />
              </div>
              <div class="promo-button-wrapper">
                <ButtonElement
                  v-if="!promosSuccess"
                  :text="$t('button_props.submit')"
                  :disabled="buttonDisabled || !form.promo_code || !!errors.promo_code"
                  @click-button="sendPromo"
                />
                <div v-if="promosSuccess" class="submitted">{{$t('prepaid.promo_applied')}}</div>
              </div>
    </div>
    <div v-if="promosSuccess" class="promo__success">
          <p class="promo_trial">{{ $t('promo.expiration_date') }} {{ formatDate(promo.expired_at) }}</p>
          {{promo.description}}
    </div>
    </div>
    <div v-if="!isSuccess" class="deposit__final">
      <div v-if="selectedAccount" class="deposit__final-block">
        <div v-if="activeAccount.tax_conditions[selectedAccount.service] === true" class="deposit__final-sum">
          <div class="deposit__final-sum__title">{{$t('deposit.top-up_amount')}}:</div>
          <div class="deposit__final-sum__value">{{ finalAmount ? locale === 'USA'? numberWithSpacesCeilFix2(finalAmount) : numberWithSpacesCeil(finalAmount) :0 }} {{$t('currency.currency_symbol')}} </div>
        </div>

        <div v-if="activeAccount.tax_conditions[selectedAccount.service] === true" class="deposit__final-nds">
          <div class="deposit__final-nds__title">{{$t('vat')}}:</div>
          <div class="deposit__final-nds__value">{{ totalNds ? totalNds :0 }} {{$t('currency.currency_symbol')}} </div>
        </div>

        <div v-if="activeAccount.commissions_conditions[selectedAccount.service]" class="deposit__final-nds">
          <div class="deposit__final-nds__title">{{$t('deposit.commission')}}:</div>
          <div class="deposit__final-nds__value">{{ currentCommissionNew }} % </div>
        </div>
        <div v-if="locale==='RU'">
            <div class="deposit__final-total">
            <div class="deposit__final-total__title">{{$t('deposit.total_amount')}}</div>

            <div 
              v-if="activeAccount.tax_conditions[selectedAccount.service] === true" 
              class="deposit__final-total__value"
            >
              {{ totalSum ? numberWithSpacesFixed(totalSum * currentRate, 2) : 0 }} {{ serviceCurrencyIcon[serviceCurrency] }}
              <!-- {{ numberWithSpacesFixed(getTotal, 2)}} {{serviceCurrencyIcon[serviceCurrency] }} -->
            </div>
            <div v-else class="deposit__final-total__value">
              {{ finalAmountWithCommission ? numberWithSpacesFixed(finalAmountWithCommission * currentRate, 2) : 0 }} {{ serviceCurrencyIcon[serviceCurrency] }}
              <!-- {{ numberWithSpacesFixed(getTotal, 2)}} {{serviceCurrencyIcon[serviceCurrency] }} -->
            </div>
          </div>

          <div v-if="serviceCurrency && serviceCurrency !== currentCurrency" class="deposit__rate">
            {{ `1${serviceCurrencyIcon[serviceCurrency]} = ${(1 / currentRate).toFixed(4)} ${serviceCurrencyIcon[currentCurrency]}` }}
            <!-- {{ `1${serviceCurrencyIcon[serviceCurrency]} = ${currentRate.toFixed(2)} ${serviceCurrencyIcon[currentCurrency]}` }} -->
          </div>
        </div>
        <div v-if="locale==='USA'">
            <div class="deposit__final-total">
            <div class="deposit__final-total__title">{{$t('deposit.total_amount')}}</div>
            <div v-if="getCurrentPlatformName ==='facebook'">
              <div v-if="activeAccount.tax_conditions[selectedAccount.service] === true" class="deposit__final-total__value">
                {{ totalSum ? numberWithSpacesFixed(totalSum * currentRate, 2) : 0 }} {{ serviceCurrencyIcon[serviceCurrency] }}
                <!-- {{ numberWithSpacesFixed(getTotal, 2)}} {{serviceCurrencyIcon[serviceCurrency] }} -->
              </div>
              <div v-else class="deposit__final-total__value">
                {{ finalAmountWithCommission ? (serviceCurrency === 'PLN' ? numberWithSpacesFixed(finalAmountWithCommission * plnRate, 2) : numberWithSpacesCeil(finalAmountWithCommission * currentRate)) : 0 }} {{ serviceCurrencyIcon[serviceCurrency] }}
                <!-- {{ numberWithSpacesFixed(getTotal, 2)}} {{serviceCurrencyIcon[serviceCurrency] }} -->
              </div>
            </div>
            <div v-else-if="getCurrentPlatformName !=='facebook'">
              <div v-if="activeAccount.tax_conditions[selectedAccount.service] === true" class="deposit__final-total__value">
                {{ totalSum  ? numberWithSpacesFixed(totalSum, 2) : 0 }} {{ serviceCurrencyIcon[serviceCurrency] }}
                <!-- {{ numberWithSpacesFixed(getTotal, 2)}} {{serviceCurrencyIcon[serviceCurrency] }} -->
              </div>
              <div v-else class="deposit__final-total__value">
                {{ finalAmountWithCommission ? numberWithSpacesFixed(finalAmountWithCommission, 2) : 0 }} {{ serviceCurrencyIcon[serviceCurrency] }}
                <!-- {{ numberWithSpacesFixed(getTotal, 2)}} {{serviceCurrencyIcon[serviceCurrency] }} -->
              </div>
            </div>
          </div>
          <div v-if="getCurrentPlatformName ==='facebook' && (serviceCurrency && serviceCurrency ==='PLN')" class="deposit__rate">
            {{ `1$ = ${(plnRate).toFixed(4)} zł` }}
            <!-- {{ `1${serviceCurrencyIcon[serviceCurrency]} = ${(1 / currentRate).toFixed(2)} ${serviceCurrencyIcon[usaCurrency]}` }} -->
          </div>
        </div>
      </div>
      <ButtonElement :text="$t('button_props.top_up')" :disabled="getButtonState" @click-button="transferAmount" />
    </div>
    <div v-if="isSuccess" class="deposit__success">
      <img class="deposit__success-icon" src="../../assets/icons/icon-success.svg" alt="success icon">
      <div class="deposit__success-title">
        {{$t('deposit.payment_credited')}}
      </div>
      <div class="deposit__success-desc">
        <!-- {{$t('deposit.replenished_amount')}} {{ numberWithSpaces(totalAmount) }} {{$t('currency.currency_symbol')}} -->
        {{$t('deposit.replenished_amount')}} {{ numberWithSpacesFix2(totalAmount) }} {{$t('currency.currency_symbol')}}
        <p v-if="locale==='USA'" class="deposit__balance-actualization">{{ $t('deposit.balance_actualization') }} </p>
      </div>
      <div class="deposit__success-button">
        <ButtonElement :text="$t('button_props.excellent')" @click-button="close" />
      </div>
    </div>
  </div>
</template>

<script>
import DepositCampaign from "@/components/ad/DepositCampaign";
import DepositAmount from "@/components/ad/DepositAmount";
import ButtonElement from "@/components/elements/ButtonElement";
import axios from "axios";
import numberWithSpaces from '@/mixin/numberWithSpaces';
import numberWithSpacesFix2 from '@/mixin/numberWithSpaces';
import checkCashback from '@/mixin/checkCashback';

export default {
  name: 'Deposit',
  components: {
    DepositCampaign,
    DepositAmount,
    ButtonElement,
  },
  mixins: [numberWithSpaces, checkCashback, numberWithSpacesFix2],
  data () {
    return {
      buttonDisabled: false,
      promosSuccess: false,
      campaignsHeight: 0,
      selectedPayType: 'balance',
      isDepositDialog: false,
      selectedAccount: null,
      showOptions: false,
      isSuccess: false,
      totalAmount: null,
      accountsListWithCampaigns: [],
      error: [],
      campaignList: [],
      disabledButton: false,
      currentCurrency: 'RUB',
      promo: {
        description :'Данный промокод начисляет дополнительные 1000 руб. на счет кэшбека сразу после активации. Промосумма может быть использована только для пополнения бюджета рекламных площадок и доступна в течение 12 месяцев после активации промокода. Вывод промосуммы на расчетный счет – невозможен.',
        expired_at: '15.09.2023'
      }, 
      serviceCurrencyIcon : {
        RUB: '₽',
        USD: '$',
        PLN: 'zł',
        EUR: '€'
      },
      locale: process.env.VUE_APP_LOCALE,
      minGooglePayment: 50,
      minPayment: 10,
      minYandexPayment: 15,
      minTelegramPayment: 1500,
      search: '',
      form: {
        promo_code : ''
      },
      errors: {
        promo_code : ''
      },
      usaCurrency: 'USD'
    }
  },
  computed: {
    formPromo() {
      return this.form.promo_code
    },
    getCurrentPlatformName() {
      const query = { ...this.$route.query }
      return this.selectedAccount ? this.selectedAccount.service : query.platform 
    },
    isCurrencyChange() {
      return this.selectedAccount.service === 'google' ? false : true
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    accountsList() {
      return this.$store.state.Ad.accountsList
    },
    depositAmount() {
      return this.$store.state.Balance.depositAmount
    },
    selectedCampaigns() {
      return this.$store.getters['Balance/selectedCampaigns']
    },
    finalAmount() {
      let amount = 0
      if (this.selectedCampaigns && this.selectedCampaigns.length) {
        this.selectedCampaigns.forEach(el => {
          amount += +el.amount
        })
      }
      if (this.$store.state.Balance.account) {
        amount += +this.$store.state.Balance.account
      }
      return amount
    },
    // finalAmountWithCommission() {
    //   return this.currentCommission ? this.finalAmount * (100 - this.currentCommission)/100 : this.finalAmount
    // },
    finalAmountWithCommission() {
      if(this.locale === 'RU') {
        return this.currentCommissionNew ? this.finalAmount * (100 - this.currentCommissionNew)/100 : this.finalAmount
      }
      return this.currentCommissionNew ? this.finalAmount / ((100 + this.currentCommissionNew)/100) : this.finalAmount
    },
    totalNds() {
      let nds = 0
      nds = (this.finalAmountWithCommission / 120) * 20
      return Math.ceil(nds)
    },
    totalSum() {
      let total = 0
      if(this.locale === "RU") {
        total= this.finalAmountWithCommission - this.totalNds
      } else if(this.locale === "USA") {
        total= this.finalAmountWithCommission
      }
      return total
    },
    amountForAccount() {
      return this.$store.state.Balance.account
    },
    currentCommission() {
      let percent = 0
      if (this.selectedAccount && this.activeAccount) {
        if (
            this.activeAccount.commissions_conditions[this.selectedAccount.service] &&
            Array.isArray(this.activeAccount.commissions_conditions[this.selectedAccount.service]) &&
            this.activeAccount.commissions_conditions[this.selectedAccount.service].length) {
          const item = this.activeAccount.commissions_conditions[this.selectedAccount.service].find((el) => this.finalAmount >= +el.min && this.finalAmount <= +el.max)
          if (item) percent = +item.percent
        } else {
          percent = +this.activeAccount.commissions_conditions[this.selectedAccount.service]
        }
      }
      return percent
    },
    currentCommissionNew() {
      let percent = 0
      if (this.selectedAccount && this.activeAccount) {
        if(this.selectedAccount.commission_replenishment) {
          percent = this.selectedAccount.commission_replenishment
        } else if (
            this.activeAccount.commissions_conditions[this.selectedAccount.service] &&
            Array.isArray(this.activeAccount.commissions_conditions[this.selectedAccount.service]) &&
            this.activeAccount.commissions_conditions[this.selectedAccount.service].length) {
            for(let el of this.activeAccount.commissions_conditions[this.selectedAccount.service]) {
              if (el.max !== null) {
                if (this.finalAmount >= +el.min && this.finalAmount <= +el.max) {
                  return el.percent;
                }
              }
            }
            for(let i = this.activeAccount.commissions_conditions[this.selectedAccount.service].length-1;i >= 0; i--){
              let el = this.activeAccount.commissions_conditions[this.selectedAccount.service][i]
              if (el.max === null) {
                if (this.finalAmount >= +el.min) {
                    return el.percent;
                }
              }
            }
            return null
        }
      }
      return percent
    },
    role() {
      return this.$store.state.Auth.role
    },
    currencyRates() {
      return this.$store.state.Auth.currencyRates
    },
    serviceCurrency() {
      return this.selectedAccount.service_currency
    },
    courcePercent() {
      return this.activeAccount.course_percents[this.selectedAccount.service_currency]
    },
    currentRate() {
      if (this.currencyRates && this.serviceCurrency && this.serviceCurrency !== this.localCurrency) {
        const rate = this.currencyRates.find(el => el.to === this.serviceCurrency && el.from === this.localCurrency)
        if (rate) {
          // return  (1+(this.courcePercent / 100)) / rate.value
          // return rate.value * (1-(this.courcePercent / 100))
          return rate.value
        } else {
          return 1
        }
      }
      return 1
    },
    plnRate() {
      if(this.localCurrency !==this.serviceCurrency && this.serviceCurrency === 'PLN') {
        const rate = this.currencyRates.find(el => el.to === this.serviceCurrency && el.from === this.localCurrency)
        if (rate && this.courcePercent) {
          return rate.value / ( 1 + this.courcePercent / 100) 
        } else if (rate) {
          return rate.value
        } else {
          return 1
        }
      }
      return 1
    },
    filtredAccountsList() {
      if (this.search && this.accountsListWithCampaigns) {
        return this.accountsListWithCampaigns.filter(
          el => 
          (el.description && el.description.toLowerCase().includes(this.search.toLowerCase())) ||
          (el.account_name && el.account_name.toLowerCase().includes(this.search.toLowerCase())) &&
          el.service !== 'promopages'
        )
      } else if (this.accountsListWithCampaigns) {
        return this.accountsListWithCampaigns.filter(el => el.service !== 'promopages')
      }
      return []
    },
    getTotal() {
      if (this.selectedAccount && this.locale === 'RU') {
        if (this.activeAccount.tax_conditions[this.selectedAccount.service] === true) {
          // return this.totalSum ? this.totalSum / this.currentRate : 0
          return this.totalSum ? this.totalSum * this.currentRate : 0
        } else {
          // return this.finalAmountWithCommission ? this.finalAmountWithCommission / this.currentRate : 0
          return this.finalAmountWithCommission ? this.finalAmountWithCommission * this.currentRate : 0
        }
      } else if (this.selectedAccount && this.locale === 'USA') {
        if (this.activeAccount.tax_conditions[this.selectedAccount.service] === true) {
          return this.totalSum ? this.totalSum * this.currentRate : 0
        } else {
          return this.finalAmountWithCommission ? this.finalAmountWithCommission : 0
        }
      }
      return 0
    },
    // getButtonState() {
    //   return (
    //     !this.finalAmount || 
    //     this.disabledButton || 
    //     Number(this.getTotal.toFixed(2)) < this.minPaymentAmount
    //   );
    // },
    getButtonState() {
      if (this.role !== 'manager' && this.role !== 'finance') {
        return (
          !this.finalAmount ||
          this.disabledButton ||
          Number(this.getTotal.toFixed(2)) < this.minPaymentAmount
        );
      } else if ((this.role === 'manager' || this.role === 'finance') && this.selectedAccount.service === 'yandex') {
        return (
          !this.finalAmount ||
          this.disabledButton ||
          Number(this.getTotal.toFixed(2)) < this.minPaymentAmount
        );
      } else {
        return false
      }
    },
    errorMessage() {
     return this.$store.state.promo.errors
    },
    minPaymentAmount() {
      const minPayment = this.selectedAccount.min_deposit || 0
      return minPayment
    },
    localCurrency() {
      return this.locale === 'USA' ? 'USD' : 'RUB'
    },
    platformsList() {
      return this.$store.state.Ad.platformsList || [];
    },
    user() {
        return this.$store.state.Auth.user
    }
  },
  watch: {
    depositAmount: {
      deep: true,
      handler() {
        this.error = []
      }
    },

    formPromo() {
      this.checkPromo()
    },

    amountForAccount() {
      this.error = []
    },
/*     currencyRates(val) {
      if (val) {
        console.log(this.currencyRates)
      }
    }, */
    showOptions(val) {
      if(val) {
        this.campaignsHeight = '30rem'
      }else {
        this.campaignsHeight =0
      }
    }
  },
  created() {
    this.$store.dispatch('Auth/getCurrencyRates', { business_account_id: this.activeAccount.id } )
  },
  mounted() {
    if (this.activeAccount && this.role === 'partner') {
       axios({ url: process.env.VUE_APP_API_URL + `/advertservice/active-accounts-in-services?business_account_id=${this.activeAccount.id}&can_finance=1`, method: 'GET' })
          .then(({ data }) => {
            if (data.data) {
              for (let key in data.data) {
                if (key) this.accountsListWithCampaigns = this.accountsListWithCampaigns.concat(data.data[key])
              }
              this.accountsListWithCampaigns = this.accountsListWithCampaigns.filter(el => {
                return this.activeAccount.services.includes(el.service)
              }).map((item)=>{
                const minDeposit = this.platformsList.find(unit => unit.slug === item.service).min_deposit
                return { ...item, min_deposit: minDeposit }
              })
            }
            // this.selectedAccount = this.$store.state.Balance.filledAccount ? this.$store.state.Balance.filledAccount : this.filtredAccountsList[0]
            const account = this.$store.state.Balance.filledAccount ? this.$store.state.Balance.filledAccount : this.filtredAccountsList[0]
            if(!account.min_deposit){
              const account_item = this.platformsList.find(unit => unit.slug === account.service)
              this.selectedAccount = { ...account, min_deposit: account_item ? account_item.min_deposit:0 }
            } else {
              this.selectedAccount = this.$store.state.Balance.filledAccount ? this.$store.state.Balance.filledAccount : this.filtredAccountsList[0]
            }
            this.$store.dispatch('Ad/getRepresentativeCampaigns', { user_id: this.user.id, ba_id: this.activeAccount.id, service: this.selectedAccount.service, account_id: this.selectedAccount.id })
              .then((data) => {
                this.campaignList = data.data.data.filter(el => el.limit_type && el.limit_type !== 'lifetime')
              })
          })
    } else if (this.activeAccount && this.role !== 'partner') {
      axios({ url: process.env.VUE_APP_API_URL + `/advertservice/active-accounts-in-services?business_account_id=${this.activeAccount.id}&can_finance=1`, method: 'GET' })
          .then(({ data }) => {
            if (data.data) {
              for (let key in data.data) {
                if (key) this.accountsListWithCampaigns = this.accountsListWithCampaigns.concat(data.data[key])
              }
              this.accountsListWithCampaigns = this.accountsListWithCampaigns
              .filter(el => this.activeAccount.visible_services[el.service])
              .map((item)=>{
                const account_item = this.platformsList.find(unit => unit.slug === item.service)
                return { ...item, min_deposit: account_item ? account_item.min_deposit:0 }
              })
            }
            const account = this.$store.state.Balance.filledAccount ? this.$store.state.Balance.filledAccount : this.accountsListWithCampaigns[0]
            if(!account.min_deposit){
              const account_item = this.platformsList.find(unit => unit.slug === account.service)
              this.selectedAccount = { ...account, min_deposit: account_item ? account_item.min_deposit:0 }
            } else {
              this.selectedAccount = this.$store.state.Balance.filledAccount ? this.$store.state.Balance.filledAccount : this.accountsListWithCampaigns[0]
            }
            this.$store.dispatch('Ad/getCampaigns', { account_id: this.selectedAccount.id })
              .then((data) => {
                this.campaignList = data.data.data.filter(el => el.limit_type && el.limit_type !== 'lifetime')
              })
          })
    }
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.deposit__step-account-selector')) {
        this.showOptions = false
      }
    }, true)
  },
  methods: {

    validatePromo(value) {
      let regex = /[а-яА-ЯёЁ\W]+/
      return regex.test(value)
    },

    checkPromo() {
      if(!this.form.promo_code) this.errors.promo_code = ''
      else if(this.form.promo_code.length < 6 || this.form.promo_code.length > 10 ) this.errors.promo_code = this.$t('prepaid.promo-length-error')
      else if (this.validatePromo(this.form.promo_code)) this.errors.promo_code = this.$t('prepaid.promo-value-error')
      else this.errors.promo_code = ''
    },

    sendPromo() {
      const data = {
        id: this.activeAccount.id,
        promo: {code:this.form.promo_code},
        token: localStorage.getItem('token') || localStorage.getItem('client_token'),
      }
      this.$store.dispatch('promo/applyPromo', data)
      .then(response => {
        this.promo.description = response.data.data.description
        this.promo.expired_at = response.data.data.expired_at
        this.promosSuccess = true
      })
      .catch(error => {
        console.log(error.message)
        this.errors.promo_code = this.errorMessage.code[0]
        // this.errors.promo_code = error.message
      }) 
    },

    showCampaignsList() {
      this.showOptions = !this.showOptions
    },
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-wallet.svg")
      } else {
        return require("@/assets/icons/icon-wallet-red.svg")
      }
    },
    getLogo(data) {
      let result = ''
      if(data==='mytarget') { 
        result = require("@/assets/icons/icon-mytarget-logo.svg")
      }else if (data==='vkontakte') {
        result = require("@/assets/icons/icon-vkontacte.svg")
      }else if (data==='vkads') {
        result = require("@/assets/icons/icon-vkreklama.svg")
      }else if (data==='facebook') {
        result = require("@/assets/icons/icon-meta.svg")
      }else if (data==='yandex') {
        result = require("@/assets/icons/yandex-direct-icon.svg")
      }else if (data==='google') {
        result = require("@/assets/icons/icon-google.svg")
      }else if (data==='tiktok') {
        result = require("@/assets/icons/icon-tiktok.svg")
      }else if (data==='telegram') {
        result = require("@/assets/icons/icon-telegram.svg")
      }else if (data==='promopages') {
        result = require("@/assets/icons/icon-promo-page.jpeg")
      }else if (data==='mediaplatform') {
        result = require("@/assets/icons/icon-media.png")
      }
      return result
    },
    selecteAccount(item) {
      if (this.$refs.campaigns) this.$refs.campaigns.resetChosenCampaigns()
      this.$store.commit('Balance/setData', { label: 'chooseCampaign', data: null })
      this.selectedAccount = item
      this.showOptions = false
      this.$store.dispatch('Ad/getCampaigns', { account_id: item.id })
        .then((data) => {
          this.campaignList = data.data.data.filter(el => el.limit_type && el.limit_type !== 'lifetime')
        })
    },
    transferAmount() {
      if (this.finalAmount < this.minPaymentAmount && (this.role === 'user' || this.role === '')) {
        this.error.push(`${this.$t('prepaid.validation_input.min')} 50`)
      } else {
        this.disabledButton = true
        const data = {
          service: this.selectedAccount.service,
          business_account_id: this.activeAccount.id,
          account_id: this.selectedAccount.id,
          total_sum: this.finalAmount,
          campaigns_amounts: this.selectedCampaigns,
          from_cashback: !(this.selectedPayType === 'balance'),
        }
        if(this.role === 'manager' || this.role === 'finance') { 
          data.token = localStorage.getItem('token')  
        }
        this.$store.dispatch('Balance/transferToCampaigns', data)
            .then(({ data }) => {
              if (data.success) {
                this.isSuccess = true
                this.totalAmount = data.data.total
                this.$store.commit('Balance/setData', { label: 'chooseCampaign', data: null })
                this.$store.commit('Balance/setData', { label: 'depositAmount', data: [] })
                this.$store.commit('Balance/setData', { label: 'filledAccount', data: null })
                this.$store.commit('Balance/setData', { label: 'account', data: null })
              }
              this.disabledButton = false
            })
            .catch((err) => {
              if (err.data.errors) {
                for (let key in err.data.errors) {
                  err.data.errors[key].forEach((el) => {
                    this.error.push(el)
                  })
                }
              }
              this.disabledButton = false
            })
      }
    },
    close() {
      this.$store.dispatch('Dialog/closeDialog')
    },
    formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
    },
  },
  beforeDestroy() {
    this.$store.commit('Balance/setData', { label: 'chooseCampaign', data: null })
    this.$store.commit('Balance/setData', { label: 'depositAmount', data: [] })
    this.$store.commit('Balance/setData', { label: 'filledAccount', data: null })
    this.$store.commit('Balance/setData', { label: 'account', data: null })
  },

}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.deposit-promo {
  padding: 0 7rem 6rem 6rem;
  min-width: 50rem;
  width: 60%;

  .promo__success {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.4rem;
    color: #7F858D;
    line-height: 16.41px;
    .promo_trial {
      color: #BFC2C6;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 1.4rem;
      margin: 0.8rem 0 2.3rem 0;
    }
}
  .prepaid__flex-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
    width: 100%;

    .prepaid__success-icon {
      max-width: 21px;
      max-height: 21px;
      position: absolute;
      z-index: 0;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
    .form-input {
     max-width: 50%;
     width: 100%;
     margin-bottom: 0;
    }
    .promo-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-width: 50%;
      width: 100%;
      height: 5rem;
      .submitted {
        color: #219653;
        font-size: 1.4rem;
        font-family: Roboto;
        font-weight: 400;
      }
      .button {
        height: 100%;
        width: 100%;
      }
  }
}
}

.deposit {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  &__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 5rem;
  }

  &__content {
    padding: 7rem 6rem;
  }

  &__final {
    width: 100%;
    background: #EFF1F4;
    padding: 3.3rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;

    &-block {
      width: 100%;
      padding-right: 4rem;
    }

    &-sum, &-nds, &-total {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      &__title {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.2rem;
        color: $grey1;
        margin-right: 1rem;
      }

      &__value {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 2.1rem;
        color: $basicBlack;
      }
    }

    &-total {
      margin-top: 3.6rem;

      &__title {
        color: $basicBlack;
        margin-right: 2rem;
      }

      &__value {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }

    .button {
      width: 27rem;
      height: 4.5rem;
    }
  }

  &__step {
    margin-bottom: 4.4rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-none {
      font-size: 1.8rem;
    }

    &-title {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #333333;
      margin-bottom: 1.8rem;

      &--small {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-bottom: 0.9rem;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      &.add-x-scroll {
         overflow-x: auto;
         min-height: 55rem;
      }
     
    }

    &-subtitle {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #7F858D;
      margin-bottom: 0.9rem;
    }

    &-row {
      display: flex;
      align-items: flex-start;
    }

    &-col {
      margin-right: 2.4rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-account-selector {
      width: auto;
      min-width: 41rem;
      margin-bottom: 1.4rem;
      position: relative;

      &-value {
        border: 1px solid #D1D4D8;
        border-radius: 2px;
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem 0 1.5rem;
        cursor: pointer;
      }

      &-name {
        display: flex;
        align-items: center;
        font-weight: 700;
        text-align: right;

        &>img {
          margin-right: 0.5rem;
          width: 2rem;
          height: 2rem;
          display: block;
        }
      }

      &-arrow {
        position: absolute;
        right: 2rem;
        transition: 0.3s ease-out;
        top: calc(50% - 8px);

        &.active {
          transform: rotate(-180deg);
        }
      }

      &-options {
        position: absolute;
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        background: #fff;
        z-index: 10;
        width: 100%;
        left: 0;
        top: calc(100% + 5px);
        max-height: 40rem;
        overflow-y: auto;
        padding: 0 2rem;
      }

      &-desc {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #333333;
      }

      &-title {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #7F858D;
        font-weight: 400;
      }

      &-item {
        height: 5.8rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s ease-out;
        font-size: 1.4rem;
        border-bottom: 1px solid #EDEFF3;

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          color: var(--primary);
        }
      }

      &-service {
        font-weight: 700;
        &>img {
          display: block;
          max-width: 2rem;
          width: 100%;
        }
      }

      &-info {
        text-align: right;
        display: flex;
        flex-direction: column;
      }

      &-error {
        color: $redLight;
        margin-top: 2rem;
      }

      &-search {
        margin: 10px 0;
      }
    }
  }

  &__balances {
    display: flex;

    &-item {
      position: relative;
      background: #FFFFFF;
      border-radius: 6px;
      border: 2px solid #fff;
      box-shadow: 0 3px 10px rgba(25, 47, 79, 0.12);
      width: 24rem;
      height: 9.2rem;
      display: flex;
      align-items: center;
      padding: 0 1.8rem;
      margin-right: 2.5rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &::before {
        content: '';
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background: url("../../assets/icons/icon-done.svg") var(--primary) no-repeat center;
        background-size: 80%;
        position: absolute;
        top: 0.7rem;
        right: 0.7rem;
        opacity: 0;
      }

      &--active {
        box-shadow: none;
        border: 2px solid var(--primary);

        &::before {
          opacity: 1;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    &-icon {
      width: 3.3rem;
      margin-right: 1.4rem;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    &-value {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      margin-top: 0.7rem;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 10rem;

    &-icon {
      margin-bottom: 2rem;
    }

    &-title {
      font-weight: 500;
      font-size: 4rem;
      line-height: 4.7rem;
      margin-bottom: 1.2rem;
    }

    &-desc {
      font-size: 2rem;
      line-height: 2.3rem;
      margin-bottom: 5rem;
    }

    &-button {
      .button {
        width: 27rem;
        height: 5rem;
      }
    }
  }

  &__rate {
    color: #7F858D;
    margin-top: -1.5rem;
  }
  &__balance-actualization {
    margin-top: 1rem;
  }
}

@include below(993px) {
  .deposit__final,
  .deposit__content{
    padding: 15px;
  }
  .deposit-promo {
    padding-left: 15px;
  }
}

@include below(769px) {
  .deposit-promo {
    padding: 0 3rem 6rem;
  }
  .deposit__step-row{
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .deposit__step-col + .deposit__step-col{
    width: 100%;
  }
  .deposit__step-col{
    margin-right: 0;
    width: 100%;
  }
  .deposit__step-account-selector{
    min-width: 0;
    width: 100%;
    max-width: 400px;
  }
  .deposit__title{
    margin-right: 35px;
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
    word-break: break-word;
  }
  .deposit-campaign{
    padding: 15px;
  }
}

@include below(500px) {
  .deposit-promo {
    min-width: 40rem;
    width: 70%;
  }
  .deposit__balances{
    flex-wrap: wrap;
    width: 100%;
  }
  .deposit__balances-item{
    width: 100%;
    margin-right: 0;
    &:not(:last-child){
      margin-bottom: 15px;
    }
  }
  .deposit__final{
    flex-wrap: wrap;
    .button{
      width: 100%;
    }
  }
  .deposit__final-block{
    width: 100%;
    padding-right: 0;
  }
}

</style>
