<template>
  <div v-loading="loading" class="ad-page page">
    <div class="ad-page__title" :class="{ 'locale-usa': locale === 'USA' }">
      <Title :text="$t('advertising.advertising_management')" />
      <div class="add-account ad-page__add-account">
        <router-link v-if="currentPlatform === 'vkontakte'" :to="{ name: 'VkAccounts' }"><span>{{ $t('add_manage_account')
            }}</span><img src="../assets/icons/icon-cross-button.svg" alt="icon with cross" /> </router-link>
        <router-link v-if="currentPlatform === 'vkads'" :to="{ name: 'VkAdds' }"><span>{{ $t('add_manage_account')
            }}</span><img src="../assets/icons/icon-cross-button.svg" alt="icon with cross" /> </router-link>
      </div>
    </div>

    <div class="ad-page__content">
      <div v-if="platformsList && platformsList.length" class="ad-page__platforms">
        <div v-for="item in platformsList" :key="item.id" class="ad-page__platforms-item"
          :class="{ active: currentPlatform === item.slug, 'locale-ru': locale === 'RU', 'locale-usa': locale === 'USA' }"
          @click="changePlatform(item.slug, activeAccount.id)">
          <el-popover placement="top" trigger="hover" :width="230" :disabled="true">
            <div style="word-break: initial; text-align: left;">
              Временно нет возможности открыть кабинет
            </div>

            <div :title="item.name" slot="reference" class="app-header__nav-more-link"
              :class="{ 'locale-usa': locale === 'USA' }">
              <PlatformCard :active="currentPlatform === item.slug" :name="item.name" :cashback="item.cashback"
                :commission="item.commission" :slug="item.slug" :not-active="item.id > 0" />
            </div>
          </el-popover>
        </div>
      </div>
      <div class="ad-page__balance" :class="{ 'locale-usa': locale === 'USA' }">
        <div>
          <span v-if="accountsList && accountsList.length && platformBalance">
            {{ $t('advertising.remainder') }}: {{ currentPlatform === 'google' ? numberWithSpacesFixed(platformBalance, 2)
              : locale === 'USA' ? numberWithSpacesCeilFix2(platformBalance) : numberWithSpacesCeil(platformBalance) }} {{
              currency_symbols[locale_currency] }}
          </span>
          <span v-if="accountsList && accountsList.length && !platformBalance">
            {{ $t('advertising.remainder') }}: {{ 0 }} {{ currency_symbols[locale_currency] }}
          </span>
        </div>
      </div>
      <div v-if="currentPlatform !== 'telegram'" class="ad-page__search"
        :class="{ 'ad-page__search--visible': accountsList && accountsList.length || searchResult === false }">
        <Search @findValue="changeSearchText" :placeholder="$t('search_props.placeholder')" />
        <div v-if="lang === 'ru'" :key="lang">
          <Selector ref="selector-status" class="ad-page__search-select" :title="$t('advertising.status')"
            :options="optionsStatusRu" @handleChange="getNewData" />
        </div>
        <div v-if="lang === 'en'" :key="lang">
          <Selector ref="selector-status" class="ad-page__search-select" :title="$t('advertising.status')"
            :options="optionsStatusEn" @handleChange="getNewData" />
        </div>
        <div class="ad-page__search-sorting">
          <div v-if="lang === 'ru'" :key="lang">
            <Selector ref="selector-sort" :title="$t('advertising.sort_by')" :options="optionsTypeRu"
              @handleChange="getSorting" />
          </div>
          <div v-if="lang === 'en'" :key="lang">
            <Selector ref="selector-sort" :title="$t('advertising.sort_by')" :options="optionsTypeEn"
              @handleChange="getSorting" />
          </div>
          <div class="ad-page__search-dir"
            :class="{ 'ad-page__search-dir--desc': sortDir === 'desc', active: params.sort_col !== null }"
            @click="sortDir === 'asc' ? changeSortParams('desc') : changeSortParams('asc')">
            <img v-if="sortDir === 'asc'" src="../assets/icons/icon-desc.svg" alt="">
            <img v-else src="../assets/icons/icon-asc.svg" alt="">
          </div>
        </div>
      </div>

      <div v-if="searchResult === false && currentPlatform !== 'telegram'">
        {{ $t('nothing_found') }}
      </div>

      <div v-if="accountsList && accountsList.length" class="ad-page__accounts">
        <div v-for="item in accountsList" :key="item.id" class="ad-page__accounts-item">
          <AccountCard :platform="currentPlatform" :info="item"
            @check-status="changePlatform(currentPlatform, activeAccount.id)" />
        </div>
        <div v-if="pagination && pagination.total > 10 &&
          pagination.lastPage !== currentPage" class="ad-page__accounts-more" :class="{ 'locale-usa': locale === 'USA' }"
          @click="showMore">
          <img :src="getPic(locale)" alt="icon" />
          {{ $t('show_more') }}
        </div>
      </div>
      <div v-if="currentPlatform !== 'facebook'"
        :class="`${accountsList && !accountsList.length ? 'ad-page__default' : 'ad-page__no-data'}`"
        v-loading="addAccountLoading">
        <div class="ad-page__default-content">
          <div v-if="accountsList && !accountsList.length" class="ad-page__default-img">
            <img :src="getMultiPic(['accounts-default.svg', 'accounts-default-usa.svg'])" alt="img" title="img">
          </div>
          <div class="ad-page__default-title" :class="{ 'locale-usa': locale === 'USA' }">
            {{ currentPlatform !== 'telegram' ? $t('advertising.add_an_account') :
              currentPlatform === 'promopages' ? $t('advertising.send_request') :
              $t('advertising.send_an_request')}}
            {{ getPlatformName(currentPlatform) }}
            {{ $t('advertising.to_manage_ads') }}
            <div v-show="currentPlatform === 'avito'" class="ad-page__info-message"
              style="margin-top: 1rem; font-weight: 400;" v-html="$t('advertising.register_avito')" />
          </div>
          <ButtonElement v-if="!showInput" class="ad-page__no-data-add" :text="$t('button_props.add_account')"
            @click-button="selectAccount(true)" />
          <div v-show="showInput && currentPlatform === 'google' && locale !== 'USA'" class="ad-page__info-message"
            v-html="$t('advertising.google_min_payment')" />
          <div v-if="locale === 'USA'" v-show="showInput && currentPlatform === 'telegram'" class="ad-page__info-message"
            v-html="$t('advertising.telegram_min_payment')" />
          <div v-if="locale === 'RU'" v-show="showInput && currentPlatform === 'telegram'" class="ad-page__info-message">
            {{ $t('advertising.telegram_min_payment') }}
          <span v-if="currencyRates && currencyRates.length">
            {{locale=== "RU" ? "(" + numberWithSpacesFixed(minTelegramRubValue) + " " + $t('advertising.rub') + ")." : null}}
          </span>
          <span>
            
          </span>
          </div>
          <span></span>
          <div class="ad-page__default-vk">
            <div v-if="showInput" class="ad-page__no-data-form">
              <div v-if="currentPlatform !== 'telegram'
                && currentPlatform !== 'tiktok'
                && currentPlatform !== 'promopages'
                && currentPlatform !== 'avito'
                && currentPlatform !== 'vkmarket'
                && currentPlatform !== 'metricapro'
                && currentPlatform !== 'mediaplatform'" class="form-input" :class="[
                { 'form-input--full': accountName },
                { 'form-input--error': error },
                { 'locale-usa': locale === 'USA' }
              ]">
                <input v-model="accountName" type="text" required>
                <Tooltip :data="getTooltip.input" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ currentPlatform === 'mytarget' ? $t('advertising.email_registered_individual') :
                    currentPlatform === 'vkontakte' ? $t('advertising.link_to_VK') :
                      currentPlatform === 'vkads' ? $t('advertising.manager_id') :
                        currentPlatform === 'facebook' ? $t('advertising.facebook_account_email') :
                          currentPlatform === 'google' && (role === 'manager' || role === 'finance') && locale === 'USA' ?
                            `${$t('id_google_ads')}` : currentPlatform === 'google' && role === 'user' && locale === 'USA' ?
                              `${$t('account_name')} ${currentPlatform}` : `${$t('user_name')} ${currentPlatform}`
                  }}
                </span>
              </div>
              <div v-if="currentPlatform === 'vkads'" class="form-input"
                :class="[{ 'form-input--full': managerName }, { 'locale-usa': locale === 'USA' }]">
                <input v-model="managerName" type="text">
                <Tooltip :data="getTooltip.manager" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('manager_name') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'mediaplatform'" class="form-input"
                :class="[{ 'form-input--full': selectedMediaPlatformOptions }, { 'locale-usa': locale === 'USA' }]">
                <MultiSelect
                  ref="multiSelectRef"
                  title="Выбор опций"
                  :options="mediaPlatformSelectOptions"
                  :selected="selectedMediaPlatformOptions"
                  :placeholder="$t('advertising.mediaplatform_choose')"
                  @handleChange="handleSelectionChange"
                />
                <Tooltip :data="getTooltip.media_platforms" />
              </div>
              <div v-if="currentPlatform === 'mediaplatform'" class="form-input"
                :class="[{ 'form-input--full': media_budget }, { 'locale-usa': locale === 'USA' }]">
                <input v-model="media_budget" type="text">
                <Tooltip :data="getTooltip.media_budget" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.mediaplatform_budget') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'mediaplatform'" class="form-input"
                :class="[{ 'form-input--full': media_contacts }, { 'locale-usa': locale === 'USA' }]">
                <input v-model="media_contacts" type="text">
                <Tooltip :data="getTooltip.media_contacts" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.mediaplatform_contact') }}
                </span>
              </div>

              <div v-if="isAllowedPlatform" class="form-input"
                :class="[{ 'form-input--full': locale === 'USA' && role === 'user' ? clientEmail : clientName }, { 'locale-usa': locale === 'USA' }]">
                <input v-if="locale !== 'USA'" v-model="clientName" type="text">
                <input v-if="locale === 'USA'" v-model="clientEmail" type="text">
                <Tooltip :data="getTooltip.name" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ currentPlatform !== 'vkads' && (locale === 'USA' && role === 'user') ? `${$t('user_email')}` :
                    `${$t(locale === 'USA' ? 'advertising.cabinet_name' : 'client_name')}` }}
                </span>
              </div>

              <div v-if="currentPlatform === 'telegram' && role === 'user'" class="form-input" :class="[
                  { 'form-input--full': accountName },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="accountName" type="text" required>
                <Tooltip :data="getTooltip.input" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ `${$t('user_name')} ${currentPlatform}` }}

                </span>
              </div>
              <div v-if="currentPlatform == 'telegram' && role === 'user'" class="form-input"
                :class="[{ 'form-input--full': tg_link }, { 'locale-usa': locale === 'USA' }]">
                <input v-model="tg_link" type="text" required>
                <Tooltip :data="getTooltip.siteLink" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.site_link') }}
                </span>
              </div>
              <div v-if="currentPlatform == 'telegram' && role === 'user'" class="form-input"
                :class="[{ 'form-input--full': tg_channel }, { 'locale-usa': locale === 'USA' }]">
                <input v-model="tg_channel" type="text">
                <Tooltip :data="getTooltip.channel" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.telegram_link') }}
                </span>
              </div>
              <div v-if="currentPlatform == 'telegram' && role === 'user'" class="form-input"
                :class="[{ 'form-input--full': advertised_product }, { 'locale-usa': locale === 'USA' }]">
                <textarea v-model="advertised_product" type="text" required />
                <Tooltip :data="getTooltip.advertising_product" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.advertised_product') }}
                </span>
              </div>

              <div v-if="currentPlatform == 'telegram' && role === 'user'" class="form-input"
                :class="[{ 'form-input--full': advertizing_budget }, { 'locale-usa': locale === 'USA' }]">

                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.advertising_budget') }}
                </span>
                <div class="form-input__width">
                  <input v-model="advertizing_budget" type="text" required>
                  <div class="form-input__icon">{{ $t('currency.currency_symbol') }}</div>
                </div>
              </div>

              <div v-if="currentPlatform === 'telegram' && role !== 'user'" class="form-input" :class="[
                  { 'form-input--full': telegram_account_id },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="telegram_account_id" type="text" required>
                <Tooltip :data="getTooltip.id" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.telegram_id') }}
                </span>
              </div>

              <div v-if="currentPlatform === 'tiktok' && role !== 'user'" class="form-input" :class="[
                  { 'form-input--full': tiktok_account_id },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_account_id" type="text" required>
                <Tooltip :data="getTooltip.account_id" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ `Tiktok ${$t('tiktok.account_id')}` }}

                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input" :class="[
                  { 'form-input--full': tiktok_account_name },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_account_name" type="text" required>
                <Tooltip :data="getTooltip.name_of_the_adds" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.account_name') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input" :class="[
                  { 'form-input--full': tiktok_company_name },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_company_name" type="text" required>
                <Tooltip :data="getTooltip.company_name" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.company_name') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input" :class="[
                  { 'form-input--full': tiktok_country },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_country" type="text" required>
                <Tooltip :data="getTooltip.country_of_incorporation" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.country_of_incorporation') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input" :class="[
                  { 'form-input--full': tiktok_sphere },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_sphere" type="text" required>
                <Tooltip :data="getTooltip.company_sphere" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.company_sphere') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input" :class="[
                  { 'form-input--full': tiktok_currency },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_currency" type="text" required disabled>
                <Tooltip :data="getTooltip.currency" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.currency') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input tiktok-min-height" :class="[
                  { 'form-input--full': tiktok_advertise_link },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_advertise_link" type="text" required>
                <Tooltip :data="getTooltip.website" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.website') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input tiktok-min-height" :class="[
                  { 'form-input--full': tiktok_email },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_email" type="text" required>
                <Tooltip :data="getTooltip.email" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.email') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="form-input tiktok-min-height" :class="[
                  { 'form-input--full': tiktok_account_timezone },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="tiktok_account_timezone" type="text" required>
                <Tooltip :data="getTooltip.timezone" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('tiktok.account_timezone') }}
                </span>
              </div>
              <div v-if="currentPlatform === 'tiktok' && role === 'user'" class="facebook-form__remark">
                {{ $t('tiktok.remark') }}
              </div>
              <div v-if="(locale !== 'USA' 
              && currentPlatform !== 'google' 
              && currentPlatform !== 'telegram' 
              && currentPlatform !== 'tiktok' 
              && currentPlatform !== 'promopages' 
              && currentPlatform !== 'avito' 
              && currentPlatform !== 'metricapro' 
              && currentPlatform !== 'vkmarket') 
              || currentPlatform === 'yandex' || currentPlatform === 'yandexbusiness'" 
              class="mark-data"
              >
                <div class="mark-data__title">{{$t('markData.formtitle')}}</div>
                <div v-if="currentPlatform === 'vkads'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': vkads_mark_user_type }
                ]">
                  <select v-model="vkads_mark_user_type">
                    <option value="juridical">
                      {{ $t('user_type.types.juridical') }}
                    </option>
                    <option value="physical">
                      {{ $t('user_type.types.physical') }}
                    </option>
                    <option value="ip">
                      {{ $t('user_type.types.ip') }}
                    </option>
                  </select>
                  <Tooltip :data="getTooltip.vkads_mark_user_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('user_type.name') }}
                  </span>
                </div>

                <label v-if="currentPlatform === 'vkads'" class="form-input"
                  :class="[{ 'form-input--full': vkads_mark_name }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkads_mark_name" type="text">
                  <Tooltip :data="getTooltip.vkontakte_mark_name">
                    <span class="tooltip-text">
                      {{ $t('user_data.vkontakteName') }}
                    </span>
                  </Tooltip>
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_name') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkads'" class="form-input"
                  :class="[{ 'form-input--full': vkads_mark_inn }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkads_mark_inn" type="text">
                  <Tooltip :data="getTooltip.vkads_mark_inn" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_inn') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkads'" class="form-input"
                  :class="[{ 'form-input--full': vkads_mark_contract_number }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkads_mark_contract_number" type="text">
                  <Tooltip v-if="getTooltip.vkads_mark_contract_number" :data="getTooltip.vkads_mark_contract_number" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_number') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkads'" class="form-input"
                  :class="[{ 'form-input--full': vkads_mark_contract_date }, { 'locale-usa': locale === 'USA' }]">
                  <el-date-picker v-model="vkads_mark_contract_date" type="date" :clearable="false" :editable="false"
                    :default-value="new Date()" :picker-options="pickerOptions" :placeholder="''">
                  </el-date-picker>


                  <Tooltip v-if="getTooltip.vkads_mark_contract_date" :data="getTooltip.vkads_mark_contract_date" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_date') }}
                  </span>
                </label>
                <div v-if="currentPlatform === 'vkads'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': vkads_mark_contract_type }
                ]">
                  <select v-model="vkads_mark_contract_type">
                    <option value="service">
                      {{ $t('mark_contract_type.types.service') }}
                    </option>
                    <option value="mediation">
                      {{ $t('mark_contract_type.types.mediation') }}
                    </option>
                  </select>
                  <Tooltip v-if="getTooltip.vkads_mark_contract_type" :data="getTooltip.vkads_mark_contract_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_type.name') }}
                  </span>
                </div>
                <div v-if="currentPlatform === 'vkads'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': vkads_mark_contract_subject }
                ]">
                  <select v-model="vkads_mark_contract_subject">
                    <option value="representation">
                      {{ $t('mark_contract_subject.types.representation') }}
                    </option>
                    <option value="distribution">
                      {{ $t('mark_contract_subject.types.distribution') }}
                    </option>
                    <option value="org_distribution">
                      {{ $t('mark_contract_subject.types.org_distribution') }}
                    </option>
                    <option value="mediation">
                      {{ $t('mark_contract_subject.types.mediation') }}
                    </option>
                    <option value="other">
                      {{ $t('mark_contract_subject.types.other') }}
                    </option>

                  </select>
                  <Tooltip v-if="getTooltip.vkads_mark_contract_subject"
                    :data="getTooltip.vkads_mark_contract_subject" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_subject.name') }}
                  </span>
                </div>

                <div v-if="currentPlatform === 'mytarget'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': mytarget_mark_user_type }
                ]">
                  <select v-model="mytarget_mark_user_type">
                    <option value="juridical">
                      {{ $t('user_type.types.juridical') }}
                    </option>
                    <option value="physical">
                      {{ $t('user_type.types.physical') }}
                    </option>
                    <option value="ip">
                      {{ $t('user_type.types.ip') }}
                    </option>
                  </select>
                  <Tooltip :data="getTooltip.mytarget_mark_user_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('user_type.name') }}
                  </span>
                </div>
                <label v-if="currentPlatform === 'mytarget'" class="form-input"
                  :class="[{ 'form-input--full': mytarget_mark_name }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="mytarget_mark_name" type="text">
                  <Tooltip :data="getTooltip.mytarget_mark_name" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_name') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'mytarget'" class="form-input"
                  :class="[{ 'form-input--full': mytarget_mark_inn }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="mytarget_mark_inn" type="text">
                  <Tooltip :data="getTooltip.mytarget_mark_inn" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_inn') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'mytarget'" class="form-input"
                  :class="[{ 'form-input--full': mytarget_mark_contract_number }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="mytarget_mark_contract_number" type="text">
                  <Tooltip v-if="getTooltip.mytarget_mark_contract_number"
                    :data="getTooltip.mytarget_mark_contract_number" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_number') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'mytarget'" class="form-input"
                  :class="[{ 'form-input--full': mytarget_mark_contract_date }, { 'locale-usa': locale === 'USA' }]">
                  <el-date-picker v-model="mytarget_mark_contract_date" type="date" :clearable="false" :editable="false"
                    placeholder="" :picker-options="pickerOptions">
                  </el-date-picker>
                  <Tooltip v-if="getTooltip.mytarget_mark_contract_date"
                    :data="getTooltip.mytarget_mark_contract_date" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_date') }}
                  </span>
                </label>
                <div v-if="currentPlatform === 'mytarget'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': mytarget_mark_contract_type }
                ]">
                  <select v-model="mytarget_mark_contract_type">
                    <option value="service">
                      {{ $t('mark_contract_type.types.service') }}
                    </option>
                    <option value="mediation">
                      {{ $t('mark_contract_type.types.mediation') }}
                    </option>
                  </select>
                  <Tooltip v-if="getTooltip.mytarget_mark_contract_type"
                    :data="getTooltip.mytarget_mark_contract_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_type.name') }}
                  </span>
                </div>
                <div v-if="currentPlatform === 'mytarget'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': mytarget_mark_contract_subject }
                ]">
                  <select v-model="mytarget_mark_contract_subject">
                    <option value="representation">
                      {{ $t('mark_contract_subject.types.representation') }}
                    </option>
                    <option value="distribution">
                      {{ $t('mark_contract_subject.types.distribution') }}
                    </option>
                    <option value="org_distribution">
                      {{ $t('mark_contract_subject.types.org_distribution') }}
                    </option>
                    <option value="mediation">
                      {{ $t('mark_contract_subject.types.mediation') }}
                    </option>
                    <option value="other">
                      {{ $t('mark_contract_subject.types.other') }}
                    </option>

                  </select>
                  <Tooltip v-if="getTooltip.mytarget_mark_contract_subject"
                    :data="getTooltip.mytarget_mark_contract_subject" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_subject.name') }}
                  </span>
                </div>


                <div v-if="currentPlatform === 'vkontakte'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': vkontakte_mark_user_type }
                ]">
                  <select v-model="vkontakte_mark_user_type">
                    <option value="legal">
                      {{ $t('user_type.types.juridical') }}
                    </option>
                    <option value="person">
                      {{ $t('user_type.types.physical') }}
                    </option>
                    <option value="individual">
                      {{ $t('user_type.types.ip') }}
                    </option>
                  </select>
                  <Tooltip :data="getTooltip.vkontakte_mark_user_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('user_type.name') }}
                  </span>
                </div>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_phone }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkontakte_mark_phone" type="text">
                  <Tooltip :data="getTooltip.vkontakte_mark_phone" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('user_data.phone_number') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_name }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkontakte_mark_name" type="text">
                  <Tooltip :data="getTooltip.vkontakte_mark_name" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_name') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_inn }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkontakte_mark_inn" type="text">
                  <Tooltip :data="getTooltip.vkontakte_mark_inn" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_inn') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_contract_number }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkontakte_mark_contract_number" type="text">
                  <Tooltip v-if="getTooltip.vkontakte_mark_contract_number"
                    :data="getTooltip.vkontakte_mark_contract_number" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_customer_contract_number') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_contract_object }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkontakte_mark_contract_object" type="text">
                  <Tooltip v-if="getTooltip.vkontakte_mark_contract_object"
                    :data="getTooltip.vkontakte_mark_contract_object" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_subject.name') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_contract_type }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="vkontakte_mark_contract_type" type="text">
                  <Tooltip v-if="getTooltip.vkontakte_mark_contract_type"
                    :data="getTooltip.vkontakte_mark_contract_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_type.name2') }}
                  </span>
                </label>
                <label v-if="currentPlatform === 'vkontakte'" class="form-input"
                  :class="[{ 'form-input--full': vkontakte_mark_contract_date }, { 'locale-usa': locale === 'USA' }]">
                  <el-date-picker v-model="vkontakte_mark_contract_date" type="date" :clearable="false"
                    :editable="false" placeholder="" :picker-options="pickerOptions">
                  </el-date-picker>
                  <Tooltip v-if="getTooltip.vkontakte_mark_contract_date"
                    :data="getTooltip.vkontakte_mark_contract_date" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_contract_date') }}
                  </span>
                </label>

                <div v-if="currentPlatform === 'yandex'" class="form-input dialog-add-account__form" :class="[
                  { 'form-input--full': yd_mark_user_type }
                ]">
                  <select v-model="yd_mark_user_type">
                    <option value="LEGAL">
                      {{ $t('user_type.types.juridical') }}
                    </option>
                    <option value="PHYSICAL">
                      {{ $t('user_type.types.physical') }}
                    </option>
                    <option value="INDIVIDUAL">
                      {{ $t('user_type.types.ip') }}
                    </option>
                  </select>
                  <Tooltip :data="getTooltip.yd_mark_user_type" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('user_type.name') }}
                  </span>
                </div>
                <label v-if="currentPlatform === 'yandex'" class="form-input"
                  :class="[{ 'form-input--full': yd_mark_inn }, { 'locale-usa': locale === 'USA' }]">
                  <input v-model="yd_mark_inn" type="text">
                  <Tooltip :data="getTooltip.yd_mark_inn" />
                  <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                    {{ $t('mark_inn') }}
                  </span>
                </label>

                <div
                  v-if="currentPlatform === 'yandexbusiness'"
                    class="form-input dialog-add-account__form"
                    :class="[
                    {'form-input--full' : yd_business_user_type }
                  ]"
                >
                  <select
                    v-model="yd_business_user_type"
                  >
                    <option value="LEGAL">
                      {{$t('user_type.types.juridical')}}
                    </option>
                    <option value="PHYSICAL">
                      {{$t('user_type.types.physical')}}
                    </option>
                    <option value="INDIVIDUAL">
                      {{$t('user_type.types.ip')}}
                    </option>
                  </select>
                  <Tooltip :data="getTooltip.yd_mark_user_type"/>
                  <span class="form-input__label" :class="{'locale-usa': locale === 'USA'}">
                    {{$t('user_type.name')}}
                  </span>
                </div>
                <label 
                  v-if="currentPlatform === 'yandexbusiness'"
                  class="form-input" 
                  :class="[{ 'form-input--full': yd_business_inn }, {'locale-usa': locale === 'USA'}]"
                >
                  <input v-model="yd_business_inn" type="text">
                  <Tooltip :data="getTooltip.yd_mark_inn"/>
                  <span class="form-input__label" :class="{'locale-usa': locale === 'USA'}">
                    {{$t('mark_inn')}}
                  </span>
                </label>
              </div>

              <div v-if="currentPlatform === 'promopages'" class="form-input" :class="[
                  { 'form-input--full': yandex_account_id },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="yandex_account_id" type="text" required>
                <Tooltip :data="getTooltip.account_id" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('yandex.account_id') }}
                </span>
              </div>

              <div v-if="currentPlatform == 'avito'" class="form-input" :class="[
                  { 'form-input--full': avito_name },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="avito_name" type="text" required>
                <Tooltip :data="getTooltip.account_name" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ `${$t('name_of_account')} ${currentPlatform}` }}
                </span>
              </div>

              <div v-if="currentPlatform === 'avito'" class="form-input" :class="[
                  { 'form-input--full': avito_id },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="avito_id" type="text" required>
                <Tooltip :data="getTooltip.account_id" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('avito.account_id') }}
                </span>
              </div>

              <div v-if="currentPlatform === 'vkmarket'" class="form-input" :class="[
                  { 'form-input--full': vkmarket_id },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="vkmarket_id" type="text" required>
                <Tooltip :data="getTooltip.account_id" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('vkmarket.account_id') }}
                </span>
              </div>

              <div v-if="currentPlatform === 'metricapro'" class="form-input" :class="[
                  { 'form-input--full': metricapro_id },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="metricapro_id" type="text" required>
                <Tooltip :data="getTooltip.account_id" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('metricapro.account_id') }}
                </span>
              </div>

              <div v-if="errors" class="form-input__error" style="padding-top: 20px;">
                <p v-for="(err, index) in errors" :key="index">
                  <span v-for="(err2, index2) in err" :key="index2">
                    {{ err2 }}
                  </span>
                </p>
              </div>

              <!-- Send button -->
              <div class="button-wrapper" :class="{ 'locale-usa': locale === 'USA' }">
                <ButtonElement class="ad-page__no-data-send" view="empty"
                  :text="isExist ? $t('button_props.send') : $t('button_props.create_account')"
                  @click-button="addAccount(true)" />
              </div>

              <div class="ad-page__no-data-back" :class="{ 'locale-usa': locale === 'USA' }" @click="showInput = false">
                {{ $t('back') }}
              </div>
            </div>
            <div v-if="currentPlatform === 'mytarget'" class="ad-page__default-mytarget__btn"
              :class="{ 'locale-usa': locale === 'USA' }" @click="openShowRegisterMytarget">
              {{ $t('advertising.how_to_register_on_myTarget') }}
            </div>
            <div v-show="currentPlatform === 'avito'" class="ad-page__info-message"
              v-html="$t('advertising.avito_add_text')" style="text-align: left; margin-top: 1rem;" />

            <div v-if="currentPlatform === 'promopages'" class="ad-page__default-mytarget__btn"
              :class="{ 'locale-usa': locale === 'USA' }" @click="openShowRegisterPromopages">
              {{ $t('advertising.how_to_register_on_promopages') }}
            </div>
            <div v-if="currentPlatform === 'vkads'" class="ad-page__default-mytarget__btn"
              :class="{ 'locale-usa': locale === 'USA' }" @click="openShowRegisterVkAdds">
              {{ $t('advertising.how_to_register_on_VK_add') }}
            </div>
            <div v-if="currentPlatform === 'metricapro'" class="ad-page__default-metricapro"
              :class="{ 'locale-usa': locale === 'USA' }">
              <b>Стоимость и тарификация:</b><br>
              <b>Стоимость подключения Метрики Про – 300 000 руб.</b>
              <p>
                На стоимость пакета влияет количество хитов (обращений) в вашем счетчике Яндекс Метрики. Хитом считается
                событие, которое учитывается Метрикой, такие как просмотры, достижение целей и отправка параметров
                визитов. Каждые 100 млн хитов повышают стоимость на 20 000 ₽.
                Число хитов за предыдущий месяц отображается в настройках любого счётчика Яндекс Метрики в блоке
                «Информация по Метрике Про»
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentPlatform === 'facebook' && role !== 'user'"
        :class="`${accountsList && !accountsList.length ? 'ad-page__default' : 'ad-page__no-data'}`"
        v-loading="addAccountLoading">
        <div class="ad-page__default-content">
          <div v-if="accountsList && !accountsList.length" class="ad-page__default-img">
            <img :src="getMultiPic(['accounts-default.svg', 'accounts-default-usa.svg'])" alt="img" title="img">
          </div>
          <div class="ad-page__default-title" :class="{ 'locale-usa': locale === 'USA' }">
            {{ currentPlatform !== 'telegram' ? $t('advertising.add_an_account') : $t('advertising.send_an_request') }}
            {{ getPlatformName(currentPlatform) }}
            {{ $t('advertising.to_manage_ads') }}
          </div>
          <ButtonElement v-if="!showInput" class="ad-page__no-data-add" :text="$t('button_props.add_account')"
            @click-button="selectAccount(true)" />
          <div class="ad-page__default-vk">
            <div v-if="showInput" class="ad-page__no-data-form">
              <div class="form-input" :class="[
                  { 'form-input--full': accountName },
                  { 'form-input--error': error },
                  { 'locale-usa': locale === 'USA' }
                ]">
                <input v-model="accountName" type="text" required>
                <Tooltip :data="getTooltip.input" />
                <span class="form-input__label" :class="{ 'locale-usa': locale === 'USA' }">
                  {{ $t('advertising.facebook_account_email') }}
                </span>
              </div>
              <div v-if="errors" class="form-input__error" style="padding-top: 20px;">
                <p v-for="(err, index) in errors" :key="index">
                  <span v-for="(err2, index2) in err" :key="index2">
                    {{ err2 }}
                  </span>
                </p>
              </div>
              <!-- Send button -->
              <div class="button-wrapper" :class="{ 'locale-usa': locale === 'USA' }">
                <ButtonElement class="ad-page__no-data-send" view="empty"
                  :text="isExist ? $t('button_props.send') : $t('button_props.create_account')"
                  @click-button="addAccount(true)" />
              </div>
              <div class="ad-page__no-data-back" :class="{ 'locale-usa': locale === 'USA' }" @click="showInput = false">
                {{ $t('back') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FacebookForm v-if="currentPlatform === 'facebook' &&
        activeAccount.visible_services.facebook &&
        (role === 'user' || role === '')" />

      <transition name="fade">
        <div ref="scroll" v-if="showRegisterMytarget && currentPlatform === 'mytarget'"
          class="ad-page__default-mytarget__content">
          <div class="ad-page__default-mytarget__title">{{ $t('advertising.how_to_register_an_advertiser_in_myTarget') }}
          </div>

          <p>{{ $t('advertising.to_register_advertiser_MyTarget') }}
            <a href="https://target.my.com" title="mytarget" target="_blank"> https://target.my.com</a>
          </p>

          <img src="@/assets/images/mytarget-img-1.jpg" alt="img" title="img">
          <p>
            {{ $t('advertising.in_open_window') }}
          </p>
          <img src="@/assets/images/mytarget-img-2.jpg" alt="img" title="img">
          <p>{{ $t('advertising.registration_page') }}</p>
          <img src="@/assets/images/mytarget-img-3.jpg" alt="img" title="img">

          <ol>
            <li>Выберите язык интерфейса–"Русский(RU)"</li>
            <li>Выберите страну "Россия"</li>
            <li>Выберите тип клиентского аккаунта "Рекламодатель"</li>
            <li>Выберите валюту интерфейсе "Российский рубль"</li>
            <li>Выберите юридический статус "Физическое лицо"</li>
            <li>При необходимости, укажите адрес электронной почты</li>
            <li>Ознакомьтесь с условиями оферты и поставьте галочку</li>
            <li>Нажмите на кнопку "Завершить регистрацию"</li>
          </ol>

          <ButtonElement :text="$t('button_props.hide')" @click-button="showRegisterMytarget = false" />
        </div>
      </transition>
      <transition name="fade">
        <div ref="scroll" v-if="showRegisterVkAdds && currentPlatform === 'vkads'"
          class="ad-page__default-mytarget__content">

          <ol>
            <li>{{ $t('advertising.vk_auth') }} <a href="https://ads.vk.com/" target="_blank"
                rel="noopener noreferrer">https://ads.vk.com/</a>{{ $t('advertising.vk_auth_id') }}.</li>
            <li>{{ $t('advertising.vk_auth_new_class') }}</li>
            <li>{{ $t('advertising.vk_auth_email') }}</li>
            <li>{{ $t('advertising.vk_auth_create_class') }}</li>
            <li>{{ $t('advertising.vk_auth_submit_mail') }}</li>
            <li>{{ $t('advertising.vk_auth_settings') }}</li>
            <li>{{ $t('advertising.vk_auth_set_id') }}</li>
          </ol>

          <div class="vkadds-title-bottom vkadds-title-bottom-margin-bottom">{{ $t('advertising.vk_auth_only_new') }}
          </div>

          <ButtonElement :text="$t('button_props.hide')" @click-button="showRegisterVkAdds = false" />
        </div>
      </transition>
      <transition name="fade">
        <div ref="scroll" v-if="showRegisterPromopages && currentPlatform === 'promopages'"
          class="ad-page__default-mytarget__content">
          <ol>
            <li>Пройти регистрацию на
              <a href="https://promopages.yandex.ru/profile/editor/create" title="promopages" target="_blank"> Яндекс
                ПромоСтраницах </a>
            </li>
            <li>Зайдите в кабинет ПромоСтраницы и перейдите в «Настройки» - «Доступы»</li>
            <li>Введите наш агентский логин <a href="mailto:profitads-ya@yandex.ru"
                title="mailto:profitads-ya@yandex.ru">profitads-ya@yandex.ru</a></li>
            <li>Выберите тип доступа «Администратор» и сохраните изменения</li>
          </ol>

          <ButtonElement :text="$t('button_props.hide')" @click-button="showRegisterPromopages = false" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import Title from "@/components/elements/Title";
import PlatformCard from "@/components/ad/PlatformCard";
import AccountCard from "@/components/ad/AccountCard";
import ButtonElement from "@/components/elements/ButtonElement";
import Search from "@/components/sort/Search";
import Selector from "@/components/sort/Selector";
import FacebookForm from "@/components/ad/FacebookForm";
import numberWithSpacesCeil from '@/mixin/numberWithSpaces'
import numberWithSpacesFixed from '@/mixin/numberWithSpaces';
import numberWithSpacesFixedFormatted from '@/mixin/numberWithSpaces';
import dateWithTimezone from '@/mixin/fixDate'
import Tooltip from '../components/elements/Tooltip.vue';
import MultiSelect from '../components/sort/MultiSelect.vue';

export default {
  name: 'Ad',
  components: {
    Title,
    PlatformCard,
    AccountCard,
    ButtonElement,
    Search,
    Selector,
    FacebookForm,
    Tooltip,
    MultiSelect
  },
  mixins: [numberWithSpacesCeil, numberWithSpacesFixed, dateWithTimezone, numberWithSpacesFixedFormatted],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      colors: {},
      currentPlatform: '',
      isExist: false,
      showInput: false,
      accountName: '',
      tiktok_account_id: '',
      tiktok_account_name: '',
      tiktok_company_name: '',
      tiktok_country: '',
      tiktok_sphere: '',
      tiktok_currency: 'USD',
      tiktok_advertise_link: '',
      tiktok_email: '',
      tiktok_account_timezone: '',
      clientName: '',
      clientEmail: '',
      managerName: '',
      error: '',
      errors: null,
      tinError: null,
      currentPage: 1,
      showRegisterMytarget: false,
      showRegisterVkAdds: false,
      params: {
        per_page: 10,
        sort_col: null,
      },
      telegram_account_id: '',
      vkads_mark_user_type: '',
      vkads_mark_name: '',
      vkads_mark_inn: '',
      vkads_mark_contract_number: '',
      vkads_mark_contract_date: '',
      vkads_mark_contract_type: '',
      vkads_mark_contract_subject: '',

      mytarget_mark_user_type: '',
      mytarget_mark_name: '',
      mytarget_mark_inn: '',
      mytarget_mark_contract_number: '',
      mytarget_mark_contract_date: '',
      mytarget_mark_contract_type: '',
      mytarget_mark_contract_subject: '',

      vkontakte_mark_user_type: '',
      vkontakte_mark_phone: '',
      vkontakte_mark_name: '',
      vkontakte_mark_inn: '',
      vkontakte_mark_contract_number: '',
      vkontakte_mark_contract_object: '',
      vkontakte_mark_contract_type: '',
      vkontakte_mark_contract_date: '',

      yd_mark_user_type: '',
      yd_mark_inn: '',
      yandex_account_id: '',

      optionsStatusRu: [
        {
          name: "Все",
          id: null
        },
        {
          name: "Активные",
          id: 'active'
        },
        {
          name: "Созданные",
          id: 'created'
        },
        {
          name: "В ожидании",
          id: 'pending'
        },
        {
          name: "Отклоненные",
          id: 'declined'
        },
        {
          name: "Удаленные",
          id: 'deleted'
        },
        {
          name: "Заблокированные",
          id: 'blocked'
        },
      ],
      optionsStatusEn: [
        {
          name: "All",
          id: null
        },
        {
          name: "Active",
          id: 'active'
        },
        {
          name: "Created",
          id: 'created'
        },
        {
          name: "Pending",
          id: 'pending'
        },
        {
          name: "Declined",
          id: 'declined'
        },
        {
          name: "Deleted",
          id: 'deleted'
        },
        {
          name: "Blocked",
          id: 'blocked'
        },
      ],
      optionsTypeRu: [
        {
          name: "Все",
          id: null
        },
        {
          name: "Название аккаунта",
          id: 'account_name'
        },
        {
          name: "Дата добавления",
          id: 'created_at'
        },
        {
          name: "Остаток",
          id: 'balance'
        },
        {
          name: "Расходы",
          id: 'spent'
        },
      ],
      optionsTypeEn: [
        {
          name: "All",
          id: null
        },
        {
          name: "Account name",
          id: 'account_name'
        },
        {
          name: "Date added",
          id: 'created_at'
        },
        {
          name: "Remainder",
          id: 'balance'
        },
        {
          name: "Expenses",
          id: 'spent'
        },
      ],
      sortDir: 'asc',
      searchResult: '',
      tin: '',
      loading: false,
      addAccountLoading: false,
      tg_link: '',
      tg_channel: '',
      advertised_product: '',
      advertizing_budget: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      account_id: '',
      allowedPlatforms: ['yandex', 'telegram', 'tiktok', 'facebook', 'promopages', 'avito', 'vkmarket', 'metricapro', 'mediaplatform', 'yandexbusiness'],
      showRegisterPromopages: false,
      currency_symbols: {
        USD: '$',
        RUB: '₽',
      },
      avito_id: '',
      vkmarket_id: '',
      metricapro_id: '',
      avito_name: '',
      media_platforms: '',
      media_budget: '',
      media_contacts: '',
      mediaPlatformSelectOptions: [
        {id: 0, name: this.$t("mediaPlatformSelect[0]")  },
        {id: 1, name: this.$t("mediaPlatformSelect[1]")  },
        {id: 2, name: this.$t("mediaPlatformSelect[2]")  },
        {id: 3, name: this.$t("mediaPlatformSelect[3]")  },
        {id: 4, name: this.$t("mediaPlatformSelect[4]")  },
        {id: 5, name: this.$t("mediaPlatformSelect[5]")  },
        {id: 6, name: this.$t("mediaPlatformSelect[6]")  },
        {id: 7, name: this.$t("mediaPlatformSelect[7]")  },
        {id: 8, name: this.$t("mediaPlatformSelect[8]")  },
        {id: 9, name: this.$t("mediaPlatformSelect[9]")  },
        {id: 10, name: this.$t("mediaPlatformSelect[10]")  },
        {id: 11, name: this.$t("mediaPlatformSelect[11]")  }
      ],
      selectedMediaPlatformOptions: [],
      yd_business_user_type : '',
      yd_business_inn : '',
    }
    
  },
  computed: {
    getTooltip() {
      switch (this.currentPlatform) {
        case 'vkontakte':
          return this.tooltipText.VK
        case 'yandex':
          return this.tooltipText.yandex
        case 'mytarget':
          return this.tooltipText.myTarget
        case 'vkads':
          return this.tooltipText.VkReclama
        case 'google':
          return this.tooltipText.googleADS
        case 'telegram':
          return this.tooltipText.telegram
        case 'tiktok':
          return this.tooltipText.tiktok
        case 'promopages':
          return this.tooltipText.promopages
        case 'avito':
          return this.tooltipText.avito
        case 'vkmarket':
          return this.tooltipText.vkmarket
        case 'metricapro':
          return this.tooltipText.metricapro
        case 'mediaplatform':
          return this.tooltipText.mediaplatform  
        case 'yandexbusiness':
          return this.tooltipText.yandexbusiness   
        default:
          return ''
      }
    },
    tooltipText() {
      return {
        myTarget: {
          input: this.$t('tooltip_text.myTarget[0]'),
          name: this.$t('tooltip_text.myTarget[1]'),
          mytarget_mark_user_type: this.$t('tooltip_text.myTarget[2]'),
          mytarget_mark_name: this.$t('tooltip_text.myTarget[3]'),
          mytarget_mark_inn: this.$t('tooltip_text.myTarget[4]')
        },
        VkReclama: {
          input: this.$t('tooltip_text.VkReclama[0]'),
          name: this.$t('tooltip_text.VkReclama[1]'),
          manager: this.$t('tooltip_text.VkReclama[2]'),
          vkads_mark_user_type: this.$t('tooltip_text.VkReclama[3]'),
          vkads_mark_name: this.$t('tooltip_text.VkReclama[4]'),
          vkads_mark_inn: this.$t('tooltip_text.VkReclama[5]')
        },
        VK: {
          input: this.$t('tooltip_text.VK[0]'),
          name: this.$t('tooltip_text.VK[1]'),
          vkontakte_mark_user_type: this.$t('tooltip_text.VK[2]'),
          vkontakte_mark_phone: this.$t('tooltip_text.VK[3]'),
          vkontakte_mark_name: this.$t('tooltip_text.VK[4]'),
          vkontakte_mark_inn: this.$t('tooltip_text.VK[5]')
        },
        yandex: {
          input: this.$t('tooltip_text.yandex[0]'),
          yd_mark_user_type: this.$t('tooltip_text.yandex[1]'),
          yd_mark_inn: this.$t('tooltip_text.yandex[2]'),
        },
        googleADS: {
          input: this.role === 'user' ? this.$t('tooltip_text.googleADS[0]') : this.$t('tooltip_text.googleADS[2]'),
          name: this.$t('tooltip_text.googleADS[1]')
        },
        VKDOP: {
          input: this.$t('tooltip_text.VKDOP[0]')
        },
        VkReclamaDOP: {
          input: this.$t('tooltip_text.VkReclamaDOP[0]')
        },
        telegram: {
          input: this.$t('tooltip_text.telegram[0]'),
          siteLink: this.$t('tooltip_text.telegram[1]'),
          channel: this.$t('tooltip_text.telegram[2]'),
          advertising_product: this.$t('tooltip_text.telegram[3]'),
          currency: this.$t('currency.currency_symbol'),
          id: this.$t('tooltip_text.telegram[4]')
        },
        tiktok: {
          account_id: this.$t('tooltip_text.tiktok[0]'),
          name_of_the_adds: this.$t('tooltip_text.tiktok[1]'),
          company_name: this.$t('tooltip_text.tiktok[2]'),
          country_of_incorporation: this.$t('tooltip_text.tiktok[3]'),
          company_sphere: this.$t('tooltip_text.tiktok[4]'),
          currency: this.$t('tooltip_text.tiktok[5]'),
          website: this.$t('tooltip_text.tiktok[6]'),
          email: this.$t('tooltip_text.tiktok[7]'),
          timezone: this.$t('tooltip_text.tiktok[8]')
        },
        promopages: {
          account_id: this.$t('tooltip_text.promopages[0]')
        },
        avito: {
          account_id: this.$t('tooltip_text.avito[0]'),
          account_name: this.$t('tooltip_text.avito[1]')
        },
        vkmarket: {
          account_id: this.$t('tooltip_text.vkmarket[0]')
        },
        metricapro: {
          account_id: this.$t('tooltip_text.metricapro[0]')
        },
        mediaplatform: {
          media_platforms: this.$t('tooltip_text.mediaplatform[0]'),
          media_budget: this.$t('tooltip_text.mediaplatform[1]'),
          media_contacts: this.$t('tooltip_text.mediaplatform[2]')
        },
        yandexbusiness: {
          input: this.$t('tooltip_text.yandex[0]'),
          yd_mark_user_type: this.$t('tooltip_text.yandex[1]'),
          yd_mark_inn: this.$t('tooltip_text.yandex[2]'),
        },
      }
    },
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    },
    visiblePlatformListUSA() {
      let arr = []
      if (this.activeAccount && this.$store.state.Ad.platformsList && this.$store.state.Ad.platformsList.length) {
        this.$store.state.Ad.platformsList.forEach((el) => {
          if (this.activeAccount.visible_services[el.slug] || this.activeAccount.existing_accounts[el.slug]) arr.push(el)
        })
      } else {
        arr = this.$store.state.Ad.platformsList
      }
      return arr
    },
    platformsRaw() {
      let arr = []
      const schema = this.locale === 'RU' ? ['avito', 'vkmarket', 'metricapro', 'telegram', 'vkontakte', 'promopages', 'vkads', 'yandex', 'mytarget', 'facebook', 'tiktok', 'google'] : ['facebook', 'tiktok', 'google', 'yandex', 'vkads']
      if (this.activeAccount && this.$store.state.Ad.platformsList && this.$store.state.Ad.platformsList.length) {
        if (this.locale === 'USA') return this.changePlatformOrder(this.visiblePlatformListUSA, schema)
        this.sortPlatforms(this.$store.state.Ad.platformsList).forEach((el) => {
          if (this.activeAccount.visible_services[el.slug] || this.activeAccount.existing_accounts[el.slug]) arr.push(el)
        })
      } else {
        if (this.locale === 'USA') arr = this.visiblePlatformListUSA
        arr = this.$store.state.Ad.platformsList
      }
      return this.changePlatformOrder(arr, schema)
    },

    platformsOrigin() {
      return this.$store.state.Ad.platformsList
    },

    platformsList() {
      let arr = []
      if (this.activeAccount && this.$store.state.Ad.platformsList && this.$store.state.Ad.platformsList.length) {
        this.$store.state.Ad.platformsList.forEach((el) => {
          if (this.activeAccount.visible_services[el.slug] || this.activeAccount.existing_accounts[el.slug]) arr.push(el)
        })
      } else {
        arr = this.$store.state.Ad.platformsList
      }

      let mediaplatform = arr.filter(platform => platform.slug === "mediaplatform");
      arr = arr.filter(platform => platform.slug !== "mediaplatform");
      let yandexIndex = arr.findIndex(platform => platform.slug === 'yandex')
      if (yandexIndex !== -1) {
        let yandexBusinessIndex = arr.findIndex(platform => platform.slug === 'yandexbusiness')
        if (yandexBusinessIndex !== -1) {
          let yandexBusiness = arr.splice(yandexBusinessIndex, 1)[0]
          arr.splice(yandexIndex + 1, 0, yandexBusiness)
        }
      }
      arr = arr.concat(mediaplatform);

      console.log('Arr', arr)
      return arr
    },
    platformBalance() {
      return this.$store.state.Ad.platformBalance
    },
    accountsList() {
      return this.$store.state.Ad.accountsList
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    pagination() {
      return this.$store.state.Ad.pagination
    },
    additionalManagerName() {
      return this.$store.state.Ad.additionalManagerName
    },
    role() {
      return this.$store.state.Auth.role
    },
    accountAddedSuccess() {
      return this.$store.state.Ad.accountAddedSuccess
    },
    currencyRates() {
      return this.$store.state.Auth.currencyRates
    },
    minTelegramRubValue() {
      if (this.currencyRates && this.currencyRates.length) {
        return 500 * 1.15 * 1.2 * this.getExchangeRate(this.currencyRates, "EUR", "RUB")
      }
      return null
    },
    isAllowedPlatform() {
      return (
        !this.allowedPlatforms.includes(this.currentPlatform) &&
        (!['google'].includes(this.currentPlatform) ||
          (this.role !== 'manager' && this.role !== 'finance'))
      );
    },
    inputForGoogleTooltip() {
      return this.role === 'user' ? this.$t('tooltip_text.googleADS[0]') : this.$t('tooltip_text.googleADS[2]')
    },
    locale_currency() {
      let currency = this.locale === 'RU' ? 'RUB' : 'USD'
      return currency
    },
    commission() {
      return this.$store.state.SetCommissions.commission
    },
  },
  watch: {
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    currentPlatform: {
      deep: true,
      handler(val) {
        if (val) {
          this.errors = []
          this.tiktok_account_id = ''
          this.tiktok_account_name = ""
          this.tiktok_advertise_link = ""
          this.tiktok_company_name = ""
          this.tiktok_country = ""
          this.tiktok_email = ""
          this.tiktok_sphere = ""
          this.tiktok_account_timezone = "",
            this.yandex_account_id = "",
            this.telegram_account_id = ""
        }
      }
    },
    activeAccount: {
      deep: true,
      handler(val) {
        if (val) {
          this.errors = null
          this.showInput = false
          const query = { ...this.$route.query }
          if (query.platform) {
            delete query.platform
          }
          this.$router.push({ query })
          this.getAccounts(this.currentPlatform, val.id)
          this.$store.commit('Ad/setData', { label: 'additionalManagerName', data: null })
          this.$store.commit('Ad/setData', { label: 'additionalManagerStatus', data: null })
          this.$store.commit('Ad/setData', { label: 'additionalManagerError', data: null })
          this.$store.commit('Ad/setData', { label: 'additionalManagerId', data: null })
        }
      }
    },
    platformsList: {
      deep: true,
      handler(val) {
        const query = { ...this.$route.query }
        if (query.platform) {
          this.currentPlatform = query.platform
        } else if (val && val.length) {
          this.currentPlatform = val[0].slug
          query.platform = val[0].slug
          this.$router.push({ query })
        }
      }
    },
    accountName() {
      this.errors = null
    },
    yandex_account_id() {
      this.errors = null
    },
    $route() {
      for (let key in this.$route.query) {
        this.params[key] = this.$route.query[key]
      }
      const query = { ...this.$route.query }
      if (query.platform) {
        this.currentPlatform = query.platform
      } else {
        this.currentPlatform = this.platformsList[0].slug
      }
      this.getAccounts(this.currentPlatform, this.activeAccount.id)
    },
    accountAddedSuccess(val) {
      if (val) {
        this.updateAfterAddedAccount()
        this.$store.commit('Ad/setData', { label: 'accountAddedSuccess', data: false })
      }
    },
    commission: {
      deep: true,
      handler(val) {
        if (val && val.commission_replenishment) {
          this.updateAccounts()
        }
      }
    },
  },
  created() {
    this.colors = this.$colors();
    this.$store.dispatch('Auth/getCurrencyRates', { business_account_id: this.activeAccount.id })
  },
  mounted() {
    if (this.platformsList && this.platformsList.length) {
      const query = { ...this.$route.query }
      if (query.platform) {
        this.currentPlatform = query.platform
      } else {
        this.currentPlatform = this.platformsList[0].slug
        query.platform = this.platformsList[0].slug
        this.$router.push({ query })
      }
      const sortParams = JSON.parse(localStorage.getItem('sortParams'))
      if (sortParams) {
        const query = { ...this.$route.query, page: 1 }

        for (let key in sortParams) {
          query[key] = sortParams[key]
        }
        if (sortParams.status) {
          let name
          if (this.lang === 'ru') {
            name = this.optionsStatusRu.find(el => el.id === sortParams.status).name
          } else if (this.lang === 'en') {
            name = this.optionsStatusEn.find(el => el.id === sortParams.status).name
          }
          this.$refs['selector-status'].serActiveName(name)
        }
        if (sortParams.sort_col) {
          let name
          if (this.lang === 'ru') {
            name = this.optionsTypeRu.find(el => el.id === sortParams.sort_col).name
          } else if (this.lang === 'en') {
            name = this.optionsTypeEn.find(el => el.id === sortParams.sort_col).name
          }
          this.$refs['selector-sort'].serActiveName(name)
        }
        if (sortParams.sort_dir) {
          this.sortDir = sortParams.sortDir
        }
        this.$router.push({ query })
          .catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
              console.log(failure.to.path)
              console.log(failure.from.path)
            }
          })
      } else {
        this.getAccounts(this.currentPlatform, this.activeAccount.id)
      }
    }
    if (this.activeAccount) {
      this.tin = this.activeAccount.inn
    }
  },
  methods: {
    changePlatformOrder(list, schema) {
      let result = [];
      for (let slug of schema) {
        let obj = list.find(item => item.slug === slug);
        if (obj) {
          result.push(obj);
        }
      }
      for (let obj of list) {
        let isInResult = result.some(item => item.slug === obj.slug);
        if (!isInResult) {
          result.push(obj);
        }
      }
      return result;
    },
    getPlatformName(currentPlatform) {
      let platform = this.platformsList.find(obj => obj.slug === currentPlatform);
      if (platform) {
        return platform.name;
      } else {
        return currentPlatform;
      }
    },
    getPic(locale) {
      if (locale === 'RU') {
        return require("@/assets/icons/icon-arrow-blue.svg")
      } else {
        return require("@/assets/icons/icon-arrow-red.svg")
      }
    },
    getMultiPic(data) {
      let result = ''
      if (this.locale === 'RU') {
        result = require(`@/assets/images/${data[0]}`)
      } else {
        result = result = require(`@/assets/images/${data[1]}`)
      }
      return result
    },
    scrollTo() {
      setTimeout(() => this.$refs["scroll"].scrollIntoView({ behavior: "smooth" }), 0)
    },
    openShowRegisterVkAdds() {
      this.showRegisterVkAdds = !this.showRegisterVkAdds
      if (this.showRegisterVkAdds) this.scrollTo()
    },
    openShowRegisterMytarget() {
      this.showRegisterMytarget = !this.showRegisterMytarget
      if (this.showRegisterMytarget) this.scrollTo()
    },
    openShowRegisterPromopages() {
      this.showRegisterPromopages = !this.showRegisterPromopages
      if (this.showRegisterPromopages) this.scrollTo()
    },
    dataPickerforceRerender(value) {
      this.lang = value
    },
    sortPlatforms(val) {
      const platforms = [...val]
      const googleIndex = platforms.findIndex(el => el.slug === 'google')
      if (googleIndex !== -1) {
        const google = platforms.splice(googleIndex, 1)
        return [...platforms, ...google]
      } else {
        return val
      }
    },
    getManager() {
      this.$store.dispatch('Ad/getAdditionalManager', {
        id: this.activeAccount.id,
        params: {
          service: this.currentPlatform,
          status: 'active'
        }
      })
    },
    changePlatform(slug, id) {
      const query = { ...this.$route.query }
      query.platform = slug
      this.currentPage = 1
      this.getAccounts(slug, id)
      this.currentPlatform = slug
      this.showInput = false
      this.showRegisterMytarget = false,
        this.showRegisterVkAdds = false
      this.$router.push({ query }),
        this.showRegisterPromopages = false
    },
    showMore() {
      this.currentPage++
      this.getAccounts(this.currentPlatform, this.activeAccount.id)
    },
    async getAccounts(slug, id) {
      this.loading = true
      const params = {
        service: slug,
        business_account_id: id,
        page: this.currentPage,
        search_text: this.params.search_text,
        status: this.params.status,
        sort_col: this.params.sort_col,
        sort_dir: this.params.sort_dir
      }
      const balanceParams = {
        service: slug,
        business_account_id: id,
      }
      await this.$store.dispatch('Ad/getPlatformBalance', balanceParams)
      await this.$store.dispatch('Ad/getAccounts', params)
        .then(({ data }) => {
          this.loading = false
          if (this.$route.query.search_text || this.$route.query.status || this.$route.query.sort_col) {
            if (!data.length) {
              this.searchResult = false
              this.showRegisterMytarget = false
            } else {
              this.searchResult = true
            }
          } else {
            if (this.accountsList.length) {
              this.searchResult = true
            } else {
              this.searchResult = ''
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    selectAccount(exist) {
      if ((this.locale !== 'USA' && this.currentPlatform !== 'google') || (this.locale === 'USA' && this.currentPlatform === 'google') || (this.currentPlatform === 'yandex' || this.currentPlatform === 'yandexbusiness')) {
        this.getLastMarkData()
      }

      this.showInput = true
      this.isExist = exist
    },
    addAccount() {
      if (this.currentPlatform === 'vkontakte') {
        if (!this.accountName.startsWith('http')) {
          this.errors[0] = [this.$t('advertising.first_field_value_link')]
          return
        }
      }
      
      if (this.currentPlatform === 'vkads') {
        this.errors = [];
        const markNameRegex = /^[А-Яа-яёЁ0-9\s—&#,.;!?‘`+*№/:|_%°«»"()\s-]*$/u;

        if (this.accountName.length && isNaN(Number(this.accountName))) {
          this.errors[0] = [this.$t('advertising.id_manager_only_numbers')]
          return
        }

        if (!markNameRegex.test(this.vkads_mark_name)) {
          this.errors[1] =[this.$t('advertising.invalid_mark_name')];
          return
        }
      }
      if (this.currentPlatform === 'yandex' || this.currentPlatform === 'yandexbusiness') {
        const regexp = /^(?=.*[a-zA-Z]{1,})(?=.*[\d]{0,})[a-zA-Z0-9.-]{1,30}$/
        this.errors = []
        if (!regexp.test(this.accountName)) {

          this.errors[0] = [this.$t('advertising.login_letter_validate')]
          this.errors[1] = [this.$t('advertising.acceptable_symbols')]
          this.errors[2] = [this.$t('advertising.login_length_limit')]
          return
        }
      }
      if (this.currentPlatform === 'google') {
        if (!this.accountName) {
          this.errors = []
          this.errors[0] = [this.$t('advertising.google_user_name')]
          return
        }
        if (this.locale === 'USA' && this.role === 'user' && !this.clientEmail) {
          this.errors = []
          this.errors[0] = [this.$t('advertising.google_user_email')]
          return
        }
      }
      if (this.currentPlatform === 'telegram') {
        if (this.role !== 'user') {
          this.errors = []
          if (!this.telegram_account_id) {
            this.errors[0] = [this.$t('advertising.telegram_account_id')]
            return
          }
        }
        if (this.role === 'user') {
          this.errors = []
          if (!this.accountName) {
            this.errors[0] = [this.$t('advertising.telegram_user_name')]
            return
          }
          if (this.accountName && !this.accountName.startsWith('@')) {
            this.errors[1] = [this.$t('advertising.telegram_user_name_starts_with')]
            return
          }
          if (!this.tg_link) {
            this.errors[2] = [this.$t('advertising.telegram_site_name')]
            return
          }
          if (!this.advertised_product) {
            this.errors[3] = [this.$t('advertising.telegram_advertise_product')]
            return
          }
          if (!this.advertizing_budget) {
            this.errors[4] = [this.$t('advertising.telegram_budget')]
            return
          }
        }

      }
      if (this.currentPlatform === 'tiktok') {
        if (this.role !== 'user') {
          this.errors = []
          if (!this.tiktok_account_id) {
            this.errors[0] = [this.$t('advertising.tiktok_account_id')]
            return
          }
        }
        if (this.role === 'user') {
          this.errors = []
          if (!this.tiktok_account_name) {
            this.errors[0] = [this.$t('advertising.tiktok_account_name')]
            return
          }
          if (!this.tiktok_company_name) {
            this.errors[1] = [this.$t('advertising.tiktok_company_name')]
            return
          }
          if (!this.tiktok_country) {
            this.errors[2] = [this.$t('advertising.tiktok_country_of_incorporation')]
            return
          }
          if (!this.tiktok_sphere) {
            this.errors[3] = [this.$t('advertising.tiktok_company_sphere')]
            return
          }
          if (!this.tiktok_currency) {
            this.errors[4] = [this.$t('advertising.tiktok_currency')]
            return
          }
          if (!this.tiktok_advertise_link) {
            this.errors[5] = [this.$t('advertising.tiktok_website')]
            return
          }
          if (!this.tiktok_email) {
            this.errors[6] = [this.$t('advertising.tiktok_email')]
            return
          }
          if (this.tiktok_email && !this.tiktok_email.includes('@')) {
            this.errors[7] = [this.$t('advertising.tiktok_correct_email')]
            return
          }
          if (!this.tiktok_account_timezone) {
            this.errors[8] = [this.$t('advertising.tiktok_account_timezone')]
            return
          }
        }
      }
      if (this.currentPlatform === 'promopages') {
        if (!this.yandex_account_id && !this.yandex_account_id.length) {
          this.errors = []
          this.errors[0] = [this.$t('advertising.id_promopages')]
          return
        }
      }
      if (this.currentPlatform === 'avito') {
        this.errors = []
        if (!this.avito_name && !this.avito_name.length) {
          this.errors[0] = [this.$t('advertising.avito_name')]
          return
        }
        if (!this.avito_id && !this.avito_id.length) {
          this.errors[1] = [this.$t('advertising.id_avito')]
          return
        }
      }
      if (this.currentPlatform === 'vkmarket') {
        if (!this.vkmarket_id && !this.vkmarket_id.length) {
          this.errors = []
          this.errors[0] = [this.$t('advertising.id_vkmarket')]
          return
        }
      }

      if (this.currentPlatform === 'mediaplatform') {
        this.errors = []
        if (this.selectedMediaPlatformOptions.length <= 0) {
            this.errors[0] = [this.$t('advertising.platforms_mediaplatform')]
            return
          }
        if (!this.media_budget && !this.media_budget.length) {
          this.errors[1] = [this.$t('advertising.budget_mediaplatform')]
          return
        }
        if (!this.media_contacts && !this.media_contacts.length) {
          this.errors[2] = [this.$t('advertising.contact_mediaplatform')]
          return
        }
      }
      if (this.currentPlatform === 'metricapro') {
        if (!this.metricapro_id && !this.metricapro_id.length) {
          this.errors = []
          this.errors[0] = [this.$t('advertising.id_metricapro')]
          return
        }
      }

      this.currentPage = 1
      const data = {
        service: this.currentPlatform,
        account_name: this.accountName,
        manager_name: this.managerName,
        business_account_id: this.activeAccount.id,
        is_exist: this.isExist,
        agency_account_type: this.activeAccount.agency_account_type[this.currentPlatform] || 'profitads',
        inn: this.tin,
        token: this.role === 'user' ? localStorage.getItem('client_token') : localStorage.getItem('token')
      }
      if (this.clientName) {
        data.user_name = this.clientName
      }

      const vkParams = {
        service: this.currentPlatform,
        account_name: this.accountName,
        agency_account_type: this.activeAccount.agency_account_type.vkontakte,
      }

      if (this.locale !== 'USA' && this.currentPlatform === 'vkontakte') {
        data.vkontakte_mark_user_type = this.vkontakte_mark_user_type
        data.vkontakte_mark_phone = this.vkontakte_mark_phone
        data.vkontakte_mark_name = this.vkontakte_mark_name
        data.vkontakte_mark_inn = this.vkontakte_mark_inn
        data.vkontakte_mark_contract_number = this.vkontakte_mark_contract_number
        data.vkontakte_mark_contract_object = this.vkontakte_mark_contract_object
        data.vkontakte_mark_contract_type = this.vkontakte_mark_contract_type
        data.vkontakte_mark_contract_date = this.vkontakte_mark_contract_date ? this.dateWithTimezone(this.vkontakte_mark_contract_date) : this.vkontakte_mark_contract_date
      }

      if (this.role === 'manager' && this.currentPlatform === 'vkontakte') {
        this.addAccountLoading = true
        this.$store.dispatch('Ad/getAdClients', vkParams)
          .then(() => {
            this.$store.commit('Ad/setData', { label: 'currentNewUser', data: data })
            this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountClients' })
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
          })
          .catch(err => {
            if (typeof err.response.data.errors === 'string') {
              this.errors = { mes: [err.response.data.errors] }
            } else {
              this.errors = err.response.data.errors
            }
          })
          .finally(() => {
            this.addAccountLoading = false
          })
      } else if (this.locale !== 'USA' && this.currentPlatform === 'vkads') {
        data.vkads_mark_user_type = this.vkads_mark_user_type
        data.vkads_mark_name = this.vkads_mark_name
        data.vkads_mark_inn = this.vkads_mark_inn
        data.vkads_mark_contract_number = this.vkads_mark_contract_number
        data.vkads_mark_contract_date = this.vkads_mark_contract_date ? this.dateWithTimezone(this.vkads_mark_contract_date) : this.vkads_mark_contract_date
        data.vkads_mark_contract_type = this.vkads_mark_contract_type
        data.vkads_mark_contract_subject = this.vkads_mark_contract_subject
        this.addAccountAction(data)
      } else if (this.locale !== 'USA' && this.currentPlatform === 'mytarget') {
        data.mytarget_mark_user_type = this.mytarget_mark_user_type
        data.mytarget_mark_name = this.mytarget_mark_name
        data.mytarget_mark_inn = this.mytarget_mark_inn
        data.mytarget_mark_contract_number = this.mytarget_mark_contract_number
        data.mytarget_mark_contract_date = this.mytarget_mark_contract_date ? this.dateWithTimezone(this.mytarget_mark_contract_date) : this.mytarget_mark_contract_date
        data.mytarget_mark_contract_type = this.mytarget_mark_contract_type
        data.mytarget_mark_contract_subject = this.mytarget_mark_contract_subject
        this.addAccountAction(data)
      } else if (this.currentPlatform === 'yandex') {
        data.yd_mark_user_type = this.yd_mark_user_type
        data.yd_mark_inn = this.yd_mark_inn
        this.addAccountAction(data)
      } else if (this.currentPlatform === 'yandexbusiness') {
        data.yd_mark_user_type = this.yd_business_user_type
        data.yd_mark_inn = this.yd_business_inn
        this.addAccountAction(data)
      }
      else if (this.currentPlatform === 'telegram'){
        const data = {
          business_account_id: this.activeAccount.id,
          account_name: this.accountName,
          site_link: this.tg_link,
          link: this.tg_channel,
          product_description: this.advertised_product,
          budget: this.advertizing_budget
        }
        const token = localStorage.getItem('token')
        const managerData = {
          business_account_id: this.activeAccount.id,
          is_exist: this.isExist,
          service: this.currentPlatform,
          agency_account_type: this.activeAccount.agency_account_type[this.currentPlatform] || 'profitads',
          account_name: this.telegram_account_id
        }

        if (this.role === 'user') {
          this.$store.dispatch('Ad/addTelegramAccount', data)
            .then((data) => {
              if (data.data.success) {
                this.$notify({
                  message: this.$t('forms_notifies.success'),
                  type: 'success'
                })
                this.accountName = '',
                  this.tg_link = '',
                  this.tg_channel = '',
                  this.advertised_product = '',
                  this.advertizing_budget = ''

              } else {
                this.$notify({
                  message: this.$t('forms_notifies.error'),
                  type: 'error'
                })
              }
            })
        }

        if (this.role !== 'user') {
          this.$store.dispatch('Ad/managerAddAccount', { data: managerData, token })
            .then((data) => {
              if (data.data.success) {
                this.$notify({
                  message: this.$t('forms_notifies.success'),
                  type: 'success'
                })
                this.telegram_account_id = ''
              } else {
                this.$notify({
                  message: this.$t('forms_notifies.error'),
                  type: 'error'
                })
              }
            }
            )
        }
      } else if (this.currentPlatform === 'tiktok') {
        const userData = {
          business_account_id: this.activeAccount.id,
          preferred_name: this.tiktok_account_name,
          company_name: this.tiktok_company_name,
          country_incorporation: this.tiktok_country,
          company_sphere: this.tiktok_sphere,
          currency: this.tiktok_currency,
          page_link: this.tiktok_advertise_link,
          emails: this.tiktok_email,
          timezone: this.tiktok_account_timezone
        }
        const token = localStorage.getItem('token')
        const managerData = {
          business_account_id: this.activeAccount.id,
          is_exist: this.isExist,
          service: this.currentPlatform,
          agency_account_type: this.activeAccount.agency_account_type[this.currentPlatform] || 'profitads',
          account_name: this.tiktok_account_id

        }
        if (this.role === 'user') {
          this.$store.dispatch('Ad/addTiktokAccount', userData)
            .then((data) => {
              console
              if (data.data.success) {
                this.$notify({
                  message: this.$t('forms_notifies.success'),
                  type: 'success'
                })
                this.tiktok_account_name = '',
                  this.tiktok_company_name = '',
                  this.tiktok_country = '',
                  this.tiktok_sphere = '',
                  this.tiktok_advertise_link = '',
                  this.tiktok_email = '',
                  this.tiktok_account_timezone = ''
              } else {
                this.$notify({
                  message: this.$t('forms_notifies.error'),
                  type: 'error'
                })
              }
            })
        }
        if (this.role !== 'user') {
          this.$store.dispatch('Ad/managerAddAccount', { data: managerData, token })
            .then((data) => {
              if (data.data.success) {
                this.$notify({
                  message: this.$t('forms_notifies.success'),
                  type: 'success'
                })
                this.tiktok_account_id = ''
              } else {
                this.$notify({
                  message: this.$t('forms_notifies.error'),
                  type: 'error'
                })
              }

            }
            )
        }
      } else if (this.currentPlatform === 'promopages') {
        data.url_param = 'yandexpromo/request'
        if (this.role === 'manager') {
          data.account_name = this.yandex_account_id
          this.addAccountAction(data)
        } else {
          data.yandex_account_id = this.yandex_account_id,
            this.addAddsAccount(data)
        }

      } else if (this.currentPlatform === 'avito') {
        const token = localStorage.getItem('token')
        data.user_name = this.avito_name,
          data.business_account_id = this.activeAccount.id,
          data.account_link = this.avito_id
        data.url_param = 'avito/request'
        if (this.role === 'user') {
          this.addAddsAccount(data)
        }

        if (this.role !== 'user') {
          data.account_name = this.avito_name
          this.$store.dispatch('Ad/managerAddAccount', { data, token })
            .then((data) => {
              if (data.data.success) {
                this.$notify({
                  message: this.$t('forms_notifies.success'),
                  type: 'success'
                })
                this.avito_name = ''
                this.avito_id = ''
              } else {
                this.$notify({
                  message: this.$t('forms_notifies.error'),
                  type: 'error'
                })
              }
            }
            )
        }

      } else if (this.currentPlatform === 'vkmarket') {

        data.account_namebusiness_account_id = this.activeAccount.id
        data.account_link = this.vkmarket_id
        data.url_param = 'vkmarket/request'

        this.addAddsAccount(data)

      } else if (this.currentPlatform === 'metricapro') {

        data.business_account_id = this.activeAccount.id
        data.account_link = this.metricapro_id
        data.url_param = 'metricapro/request'

        this.addAddsAccount(data)
      } else if (this.currentPlatform === 'mediaplatform') {
        const mediaplatformData = {
          business_account_id: this.activeAccount.id,
          platforms: this.getNamesString(this.selectedMediaPlatformOptions),
          budget : this.media_budget,
          contacts: this.media_contacts
        }
        this.addAccountLoading = true
        this.addMediaPlatformAction(mediaplatformData)
       
      } else {
        this.addAccountLoading = true
        if (this.currentPlatform === 'google') {
          const gmailRegexp = /^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$/g
          const noGmail = !gmailRegexp.test(this.accountName)
          data.is_exist = noGmail
          data.agency_account_type = this.activeAccount.agency_account_type.google || 'profitads'
        }
        if (this.currentPlatform === 'google' && this.locale === 'USA' && this.role === 'user') {
          data.account_url = 'google/request'
          data.email_google = this.clientEmail
          this.addRequestAction(data)
        } else {
          this.addAccountAction(data)
        }
      }
    },
    addAddsAccount(data) {
      this.$store.dispatch('Ad/addAddsAccount', data)
        .then((data) => {
          if (data.data.success) {
            this.$notify({
              message: this.$t('forms_notifies.success'),
              type: 'success'
            })
            this.metricapro_id = ''
            this.updateAfterAddedAccount()
          } else {
            this.$notify({
              message: this.$t('forms_notifies.error'),
              type: 'error'
            })
          }
        })
        .catch(err => {
          this.addAccountLoading = false
          console.error(err)
        })
    },

    async addAccountAction(data) {
      await this.$store.dispatch('Ad/addAccount', data)
        .then(() => {
          this.$notify({
            message: this.$t('advertising.add_account_success'),
            type: 'success'
          });
          this.updateAfterAddedAccount()
        })
        .catch(err => {
          console.log('err', err.response.data.errors)
          this.addAccountLoading = false
          if (typeof err.response.data.errors === 'string') {
            this.errors = { mes: [err.response.data.errors] }
          } else {
            this.errors = err.response.data.errors
          }
        })
    },
    addRequestAction(data) {
      this.$store.dispatch('Ad/addRequest', data)
        .then(() => {
          this.updateAfterAddedAccount()
        })
        .catch(err => {
          this.addAccountLoading = false
          console.error(err)
        })
    },
    addMediaPlatformAction(data) {
      this.$store.dispatch('Ad/addMediaPlatformAccount', data)
        .then(() => {
          this.addAccountLoading = false
          this.selectedMediaPlatformOptions = []
          this.media_budget = ''
          this.media_contacts = ''
          this.$refs.multiSelectRef.choseAll(false)
        })
        .catch(err => {
          this.addAccountLoading = false
          console.error(err)
        })
    },
    openAdManagerDialog() {
      this.$store.commit('Dialog/setData', { label: 'managerFor', data: this.currentPlatform })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AdditionalManager' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    changeSearchText(val) {
      this.accountsList.length = 0;
      this.currentPage = 1;
      if (!val) {
        const query = { ...this.$route.query, page: 1, search_text: null }
        this.$router.push({ query })
      } else {
        const query = { ...this.$route.query, page: 1, search_text: val }
        this.$router.push({ query })
      }
    },
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({ query })
    },
    getNewData(val) {
      this.accountsList.length = 0;
      this.currentPage = 1
      if (val === 0) {
        const query = { ...this.$route.query, page: 1, status: null }
        this.$router.push({ query })
        this.checkLocalStorageSortParams('status', null)
      } else {
        const query = { ...this.$route.query, page: 1, status: val }
        this.$router.push({ query })
        this.checkLocalStorageSortParams('status', val)
      }
    },
    getSorting(val) {
      this.accountsList.length = 0;
      this.currentPage = 1
      if (val === 0) {
        const query = { ...this.$route.query, page: 1, sort_col: null }
        this.$router.push({ query })
        this.checkLocalStorageSortParams('sort_col', null)
      } else {
        const query = { ...this.$route.query, page: 1, sort_col: val }
        this.$router.push({ query })
        this.checkLocalStorageSortParams('sort_col', val)
      }
    },
    changeSortParams(direction) {
      this.accountsList.length = 0;
      this.currentPage = 1
      this.sortDir = direction
      this.params.sort_dir = direction
      const query = { ...this.$route.query, sort_dir: direction, page: 1, }
      this.checkLocalStorageSortParams('sort_dir', direction)
      this.$router.push({ query })
    },
    checkLocalStorageSortParams(param, value) {
      const params = localStorage.getItem('sortParams')
      let paramsObject = {}
      if (params) {
        paramsObject = JSON.parse(params)
      }
      paramsObject[param] = value
      localStorage.setItem('sortParams', JSON.stringify(paramsObject))
    },
    updateAfterAddedAccount() {
      setTimeout(() => {
        this.getAccounts(this.currentPlatform, this.activeAccount.id)
        this.accountName = ''
        this.clientName = ''
        this.managerName = ''
        this.showInput = false
        this.addAccountLoading = false
        this.clientEmail = ''
        this.avito_name = ''
        this.avito_id = ''
      }, 1000)
    },

    updateAccounts() {
      this.getAccounts(this.currentPlatform, this.activeAccount.id)
    },

    getLastMarkData() {
      const params = {
        service: this.currentPlatform,
        business_account_id: this.activeAccount.id,
      }
      this.$store.dispatch('Ad/getLastMarkData', params)
        .then((resp) => {
          const respData = resp.data.data
          for (let key in respData) {
            if (key === 'vkontakte_mark_contract_date') {
              this[key] = respData[key].split('.').reverse().join('-')
            } else {
              this[key] = respData[key]
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getExchangeRate(array, from, to) {
      for (let i = 0; i < array.length; i++) {
        let obj = array[i]
        if (obj.from && obj.to && obj.value) {
          if (obj.from === from && obj.to === to) {
            return obj.value
          }
        }
      }
      return null
    },
    handleSelectionChange(selected) {
      this.selectedMediaPlatformOptions = selected;
    },
    getNamesString(objectsArray) {
      return objectsArray.map(obj => obj.name).join(', ');
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.ad-page__no-data-form {
  .form-input {

    textarea {
      min-height: 100px;
      font-family: Arial, sans-serif;
      color: #333;
    }
  }
}

.tooltip-wrapper {
  position: absolute;
  left: 100%;
  top: 25%;
}

.add-account {
  >a {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    >img {
      margin-left: 1rem;
    }
  }

  .form-input--full {
    position: relative;

    .tooltip-wrapper {
      position: absolute;
      left: 100%;
      top: 25%;
    }
  }
}

// ad-page__platforms
.ad-page__platforms::-webkit-scrollbar {
  height: 12px;
}

.ad-page__platforms::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.ad-page__platforms::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.vkadds-title-bottom {
  font-weight: 700;
}

.vkadds-title-bottom-margin-bottom {
  margin-bottom: 2rem;
}

.ad-page {
  .el-loading-spinner {
    top: 20rem;
  }

  &__title {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.locale-usa {
      margin-bottom: 3.2rem;

      .title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
      }
    }
  }

  &__platforms {
    margin: 0 -0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;

    &-item {
      opacity: 0.5;
      transition: 0.3s ease-out;
      padding: 0 0.5rem;
      width: unset;

      &.active {
        opacity: 1;
      }

      &.locale-usa {
        &.active {
          .platform {
            border: 2px solid var(--primary);
            background: #fff;
          }

          border-radius: 6px;
        }

        .platform {
          background: none;
          box-shadow: none;

        }
      }
    }
  }

  &__balance {
    &.locale-usa {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: var(--black);
      opacity: 0.5;
    }

    margin: .8rem 0 2.4rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #6C7285;
  }

  &__default {
    padding: 6rem 0;

    &-content {
      max-width: 59rem;
      margin: 0 auto;
    }

    &-img {
      width: 34rem;
      margin: 0 auto 6rem;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.3rem;
      text-align: center;
      color: $basicBlack;
      margin: 0 0 3rem;

      &.locale-usa {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
      }
    }

    &-vk {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .ad-page__no-data-add {
      display: block;
      margin: 0 auto 2rem;
    }

    .ad-page__no-data-form {
      margin: 0 auto;
    }

    &-mytarget {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__btn {
        cursor: pointer;
        position: relative;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.9rem;
        color: var(--primary);
        text-align: center;
        width: max-content;
        margin: 0 auto;

        &::before {
          width: 0;
          height: 1px;
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: var(--primary);
          opacity: 0;
          transition: all .4s;
        }

        &:hover {

          &::before {
            width: 100%;
            left: 0;
            opacity: 1;
          }
        }
      }

      &__content {
        width: 100%;
        margin-top: 4rem;

        &__title {
          font-size: 2rem;
          font-weight: 700;
          line-height: 2.3rem;
          margin: 0 0 3rem;
        }

        p {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.9rem;
          color: $basicBlack;
          margin: 0 0 1.6rem;
        }

        a {
          font-weight: 700;
          transition: color .4s;

          &:hover {
            color: $primary;
          }
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 0 4rem;
        }

        ol {
          padding: 0 0 0 2rem;
          margin: 0 0 3rem;

          li {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.9rem;
            color: $basicBlack;
            margin: 0 0 2rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &__accounts {
    &-item {
      margin-bottom: 2rem;
    }

    &-more {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      box-shadow: 0 5px 10px rgba(158, 158, 158, 0.1);
      border-radius: 4px;
      background: #fff;
      height: 3rem;
      cursor: pointer;
      margin-bottom: 1.4rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: var(--primary);

      &>img {
        height: 2rem;
        width: 2rem;
        margin-right: 0.4rem;
        display: block;
      }

      &.locale-usa {
        background: none;
        box-shadow: none;
        margin-top: 2.4rem;
        line-height: 0;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
      }
    }
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0;
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;


    .button-wrapper {
      &.locale-usa {
        display: flex;
        justify-content: center;

        button {
          min-height: 45px;
          padding: 0 27px;
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          border-width: 3px;
          border-radius: 8px;
          width: auto;
        }
      }
    }

    &-title {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.3rem;
      color: $text;
      margin-bottom: 3.4rem;
    }

    &-add {
      width: 44rem;
      display: block;
      margin: 0 auto 2rem;
    }

    &-create {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $primary;
      cursor: pointer;
      transition: 0.3s ease-out;

      &:hover {
        color: $text;
      }
    }

    &-form {
      max-width: 43rem;
      width: 100%;
      margin-bottom: 2rem;
    }

    &-send {
      width: 100%;
      margin-top: 2rem;
    }

    &-back {
      cursor: pointer;
      margin-top: 1rem;
      text-align: center;

      &.locale-usa {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        color: #9B9FA8;
        margin-top: 1.6rem;
      }
    }
  }

  &__info-message {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.6rem;
    color: #7F858D;
    line-height: 120%;
  }

  &__manager {
    display: flex;
    justify-content: center;

    &-btn {
      width: 44rem;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      outline: none;
      color: $primary;
      margin-bottom: 1.6rem;

      &--gray {
        color: $gray;
        border-color: $gray;

        &:hover {
          color: $basicWhite;
          background-color: $gray;
        }
      }
    }
  }

  &__search {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2.4rem;

    .search {
      width: 38rem;
      margin: 0 2rem 0 0;
    }

    &-dir {
      pointer-events: none;
      cursor: pointer;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: .3;
      transition: opacity .4s;

      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        transition: transform .4s;
        transform: translateZ(0);
      }

      &--desc {

        svg {
          transform: scale(1, -1);
        }
      }

      &.active {
        pointer-events: unset;
        opacity: 1;
      }
    }

    &--visible {
      display: flex;
    }
  }
}

.ad-page__search-sorting.ad-page__search-sorting {
  display: flex;
  align-items: center;
  margin-right: 0;

  .selector {
    flex-grow: 1;
  }
}

.ad-page__search-dir {
  margin-left: 2rem;
}

@include below(769px) {
  .ad-page__platforms {
    flex-wrap: wrap;
    margin-bottom: -1rem;
  }

  .ad-page__platforms-item {
    margin-bottom: 1rem;

    .platform__logo {
      width: auto;
      white-space: normal;
    }
  }

  .ad-page__search {
    flex-wrap: wrap;

    .ad-page__search-select,
    .search {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }

    .ad-page__search-sorting,
    .selector__content.selector__content {
      width: 100%;
    }
  }

  .ad-page__no-data {
    padding: 30px 15px;

    >div {
      max-width: 100%;
    }


    @include below(576px) {
      overflow: hidden;
      .form-input {
        width: calc(100% - 2rem);

        .tooltip-wrapper {
          top: 50%;
        }
      }
    }
  }

  .ad-page__manager-btn.ad-page__manager-btn,
  .ad-page__no-data-add {
    max-width: 100%;
    min-width: 0;
    white-space: normal;
    height: auto;
    min-height: 3.5rem;
    padding: 5px 10px;
  }
}

@include below(560px) {
  .add-account {
    padding-left: 0;
    margin-top: 2rem;
  }

  .ad-page {
    &__title {
      flex-wrap: wrap;
    }
  }
}

@include below(500px) {
  .ad-page__platforms {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 48%));
  }

  .ad-page__platforms-item {
    .platform {
      justify-content: center;
    }

    .platform__logo {
      width: auto;

      img {
        margin: 0;
      }

      font-size: 0;

    }
  }

  .ad-page__no-data-form {
    .form-input {
      position: relative;
      display: flex;
      flex-direction: column;

      &__width {
        position: relative;

      }

      &.locale-usa {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        color: var(--black);

        input {
          border: 2px solid var(--black);
          padding: 1.6rem;
        }
      }
    }

    .form-input__label {
      position: static;
      order: -10;
      margin-bottom: 5px;
    }
  }
}

.mark-data {
  padding: 20px 0 30px;
  margin-top: 20px;
  position: relative;

  &__title {
    position: relative;
    z-index: 5;
  }

  &::before {
    content: '';
    background: #F5F7FA;
    display: block;
    width: calc(100% + 10rem);
    position: absolute;
    left: -5rem;
    top: 0;
    bottom: 0;
  }

  .form-input {
    display: block;
    margin-top: 2rem !important;
  }

  .form-input input {
    background: #fff;
  }

  .form-input__label {
    z-index: 5;
  }

  .form-input--full .form-input__label {
    z-index: 5;
  }
}
</style>
